<script setup>
import { defineProps } from 'vue'
import { useGlobalStore } from '@/stores/global';
import BasePageBanner from './BasePageBanner.vue';
import BaseBreadcrumb from './BaseBreadcrumb.vue';

const globalStore = useGlobalStore()
const globalData = globalStore.data

const props = defineProps({
    bgColor: {
        type: String,
    },
    AsideData: {
        type: Object,
        default() {
            return {
                title: "",
                menuData: {
                    mainUrl: "",
                    active: "",
                    list: []
                }
            }
        }
    },
    BannerData: {
        type: Object,
        required: true,
        default() {
            return {
                pageName: "",
                backgroundImgUrl: "",
                title: "",
                subTitle: "",
            }
        } 
    },
    BreadcrumbData: {
        type: Array,
        required: true,
        default() {
            return [
                { text: "首頁", textEn: "Home", to: "/" }
            ]
        }
    }
})

</script>
<template>
    <div class="BasePageLayout">
        <BasePageBanner :BannerData="props.BannerData" />
        <el-container>
            <el-aside v-show="props.AsideData.title !== '' && props.AsideData.menuData.list.length > 0">
                <BaseBreadcrumb class="d-block d-lg-none" :BreadcrumbData="props.BreadcrumbData" />
                <div v-if="props.AsideData.title !== '' && props.AsideData.menuData.list.length > 0" class="content">
                    <div class="Title">{{ $t(props.AsideData.titleKey) }}</div>
                    <ul v-if="props.AsideData.menuData.list.length > 0" class="Menu">
                        <li v-for="(category, index) in props.AsideData.menuData.list" :key="index" :span="3">
                            <a v-if="category.to" class="MenuLink" :href="category.to">{{ category.titleKey? $t(category.titleKey):category.title }}</a>
                            <a v-else class="MenuLink" :class="{ 'active': category.alias === globalData.currentHash || category.alias === props.AsideData.menuData.active }" :href="category.alias? props.AsideData.menuData.mainUrl+'#'+category.alias:props.AsideData.menuData.mainUrl">{{ category.titleKey? $t(category.titleKey):category.title }}</a>
                        </li>
                    </ul>
                </div>               
            </el-aside>
            <el-main :class="props.bgColor? 'bg-'+props.bgColor:''">
                <div class="MainContainer" :class="{ 'noAside': props.AsideData.title === '' && props.AsideData.menuData.list.length < 1 }">
                    <BaseBreadcrumb class="d-none d-lg-block" :BreadcrumbData="props.BreadcrumbData" />
                </div>
                <slot />
            </el-main>
        </el-container>
    </div>
</template>
<style lang="scss" scope>
.BasePageLayout {
    position: relative;
    .el-container {
        padding-top: 370px;;
        @media screen and (max-width: $Tab) {
            position: relative;
            flex-direction: column;
            padding-top: 230px;
        }
    }
    .el-aside {
        display: flex;
        justify-content: end;
        padding: 80px 46px;
        background-color: $color-icon-bg-gray;
        max-width: 500px;
        width: 26vw;
        @media screen and (max-width: $Tab) {
            flex-direction: column;
            max-width: none;
            width: 100%;
            padding: 36px 20px;
        }
        .content{
            width: 100%;
            max-width: 240px;
            margin-top: 95px;
            @media screen and (max-width: $Tab) {
                max-width: none;
                margin-top: 0;
            }

        }
        .Title {
            color: $color-black;
            margin-bottom: 20px;
            font-size: 1.25rem;
            font-weight: bold;
            @media screen and (max-width: $Tab) {
                margin-bottom: 16px;
            }
        }
        .Menu {
            margin: 20px 0;
            padding: 30px 0;
            border-top: 1px solid $color-dash-border;
            border-bottom: 1px solid $color-dash-border;
            @media screen and (max-width: $Tab) {
                width: 100%;
                display: flex;
                flex-wrap: wrap;
                margin: 16px 0;
                padding: 16px 0;
                li {
                    width: 50%;
                }
            }
            .MenuLink {
                display: block;
                text-align: left;
                padding: 11px;
                width: 100%;
                color: $color-primary-black;
                font-size: 1rem;
                border-radius: 10px;
                &:hover{
                    background-color: $color-primary;
                    color: white;
                }
                &.active {
                    background-color: white;
                    color: $color-primary;
                }
            }
        }
    }
    .el-main {
        padding: 0;
        background-color: $color-icon-bg-gray;
        &.bg-defaultO4 {
            background-color: $color-icon-bg-gray-o4;
        }
        .MainContainer{
            position: relative;
            max-width: 1016px;
            @media screen and (max-width: $Tab) {
                position: static;
            }
            &.noAside {
                max-width: 1350px;
            }
        }
    }
}
</style>
<script setup>
import { ref, defineProps, onMounted } from 'vue'
import SectionNewsItem from "./SectionNewsItem.vue"
import BaseBtn from "@/components/Base/BaseBtn.vue"

const props = defineProps({
    VideoData: {
        type: Object,
        required: true,
        default() {
            return {
                headline: [],
                list: []
            }
        }
    }
})
const activeIndex = ref(0)
const isFadingOut = ref(false)
const isFadingIn = ref(false)
const goVideoCarouselIndex = (index) => {
    isFadingOut.value = true
    isFadingIn.value = false
    setTimeout(() => {
        isFadingOut.value = false
        activeIndex.value = index
        isFadingIn.value = true
    }, 1000);
}
const showCarouselSpot = ref("none")
const VideoCarouselMobile = ref(null)
const VideoCarouselMobileHandler = (str) => {
    switch(str) {
        case "right":
            VideoCarouselMobile.value.next()
        break
        case "left":
            VideoCarouselMobile.value.prev()
        break
    }
}
const previousVideoCarouse = () => {
    if(activeIndex.value === 0) {
        goVideoCarouselIndex(props.VideoData.headline.length - 1)
    } else {
        goVideoCarouselIndex(activeIndex.value - 1)
    }
}
const nextVideoCarouse = () => {
    if(activeIndex.value === props.VideoData.headline.length - 1) {
        goVideoCarouselIndex(0)
    } else {
        goVideoCarouselIndex(activeIndex.value + 1)
    }
}
// const getVideoList = () => {
//     let result = []
//     if(props.VideoData.list.length > 0) {
//         if(props.VideoData.list.length < 4) {
//             let tempIndex = 0
//             for(let i = 0; i < 4; i++){
//                 if(props.VideoData.list[i]) {
//                     result.push(props.VideoData.list[i])
//                 } else {
//                     if(props.VideoData.list[tempIndex]) {
//                         result.push(props.VideoData.list[tempIndex])
//                         if(props.VideoData.list[tempIndex + 1]){
//                             tempIndex++
//                         }
//                     }
//                 }
//             }
//         }
//     }
//     return result
// }
onMounted(() => {
	setTimeout(() => {
		showCarouselSpot.value = ""
	}, 5000)
})
</script>
<template>
    <div id="video" class="SectionVideo">
        <div class="container">
            <div class="SectionHeader">
                <div class="Title">
                    <div class="SectionTitle"><img v-if="$i18n.locale !== 'zh-TW'" class="icon" src="@/assets/images/icon/title_icon.svg" alt="">Video</div>
                    <div class="SectionSubTitle" v-if="$i18n.locale === 'zh-TW'">
                        <img class="icon" src="@/assets/images/icon/title_icon.svg" alt="">影音專區
                    </div>
                </div>
            </div>
            <div class="d-none d-lg-block">
                <div class="VideoCarousel" v-if="props.VideoData.headline.length > 0">
                    <el-button class="ArrowBtn Left" circle @click="previousVideoCarouse()"><font-awesome-icon :icon="['fas', 'chevron-left']" /></el-button>
                    <el-button class="ArrowBtn Right" circle @click="nextVideoCarouse()"><font-awesome-icon :icon="['fas', 'chevron-right']" /></el-button>
                    <el-row v-if="props.VideoData.headline[activeIndex]" :gutter="24">
                        <el-col :span="12">
                            <RouterLink :to="{ name: 'video-page', params: { alias: props.VideoData.headline[activeIndex].alias } }">
                                <div class="VideoCarouselContent">
                                    <div class="VideoCarouselContentBox" :class="{ 'FadeOut': isFadingOut, 'FadeIn': isFadingIn }">
                                        <div class="Tag">
                                            <span>{{ props.VideoData.headline[activeIndex].category_title }}</span>
                                        </div>
                                        <el-text line-clamp="2" class="Title">
                                            {{ props.VideoData.headline[activeIndex].title }}
                                        </el-text>
                                        <el-text line-clamp="3" class="Content">{{ props.VideoData.headline[activeIndex].introtext }}</el-text>
                                        <div class="Info">
                                            <el-tag effect="plain" round>{{ props.VideoData.headline[activeIndex].tags? props.VideoData.headline[activeIndex].tags[0].title:"" }}</el-tag>
                                            <span>{{ $formater.dateFormate(props.VideoData.headline[activeIndex].publish_up, "Y/M/D") }}</span>
                                        </div>
                                    </div>
                                </div>
                            </RouterLink>
                        </el-col>
                        <el-col :span="12">
                            <div class="VideoCarouselVideo">
                                <div class="VideoCarouselVideoBox" >
                                    <div v-if="props.VideoData.headline[activeIndex].extrafields" class="Video" :class="{ 'FadeOut': isFadingOut, 'FadeIn': isFadingIn }">
                                        <iframe width="100%" height="100%" :src="'https://www.youtube.com/embed/' + $formater.videoIdFormate(props.VideoData.headline[activeIndex].extrafields.link.value)" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                                    </div>
                                </div>
                                <div class="VideoCarouselSpot">
                                    <ul class="SpotList">
                                        <li v-for="index in props.VideoData.headline.length" :key="index" class="SpotItem">
                                            <button class="Spot" :class="{ 'active': activeIndex === index - 1 }" aria-label="btn" @click="goVideoCarouselIndex(index - 1)"></button>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </el-col>
                    </el-row>
                </div>
                <!-- 不需要此區塊 -->
                <div v-if="false" class="VideoList">
                    <el-row :gutter="24">
                        <el-col v-for="item in props.VideoData.list" :key="item" :md="6" :sm="6"  class="NewsItem">
                            <router-link :to="{ name: 'video-page', params: { alias: item.alias } }"><SectionNewsItem :ItemData="item" /></router-link>
                        </el-col>
                    </el-row>
                </div>
                <!-- 到這裡 -->
                <div class="BottomRow">
                    <div style="width: 225px; margin: auto">
                        <BaseBtn :color="'white'" :text="$t('BTN_ALLVIDEO')" :icon="['fas', 'chevron-right']" :to="'/video'" />
                    </div>
                </div>
            </div>
            <div v-if="props.VideoData.headline[activeIndex]" class="d-block d-lg-none">
                <el-carousel ref="VideoCarouselMobile" class="VideoCarouselMobile" :autoplay="false" :indicator-position="showCarouselSpot" arrow="never" height="520px" style="margin-bottom: 30px;">
                    <el-carousel-item v-for="item in props.VideoData.headline" :key="item">
                        <RouterLink :to="{ name: 'video-page', params: { alias: props.VideoData.headline[activeIndex].alias } }">
                            <div class="VideoCarouselMobileContent">
                                <div class="VideoCarouselMobileContentBox">
                                    <div class="Tag">
                                        <span>{{ $i18n.locale === 'zh-TW' ? props.VideoData.headline[activeIndex].category_title:props.VideoData.headline[activeIndex].tagEn }}</span>
                                    </div>
                                    <div class="Title">
                                        {{ $i18n.locale === 'zh-TW' ? props.VideoData.headline[activeIndex].title:props.VideoData.headline[activeIndex].titleEn }}
                                    </div>
                                    <el-text line-clamp="3" class="Content">{{ $i18n.locale === 'zh-TW' ? props.VideoData.headline[activeIndex].introtext:props.VideoData.headline[activeIndex].contentEn }}</el-text>
                                    <div class="Info">
                                        <el-tag effect="plain" round>{{ props.VideoData.headline[activeIndex].tags? props.VideoData.headline[activeIndex].tags[0].title:"" }}</el-tag>
                                        <span>{{ $formater.dateFormate(props.VideoData.headline[activeIndex].publish_up, "Y/M/D") }}</span>
                                    </div>
                                </div>
                            </div>
                        </RouterLink>
                        <div class="VideoCarouselMobileVideo">
                            <div v-if="props.VideoData.headline[activeIndex].extrafields" class="Video">
                                <iframe width="100%" height="100%" :src="'https://www.youtube.com/embed/' + $formater.videoIdFormate(props.VideoData.headline[activeIndex].extrafields.link.value)" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                            </div>
                        </div>
                    </el-carousel-item>
                </el-carousel>
                <el-row>
                    <el-col :span="12">
                        <BaseBtn :color="'white'" :text="$t('BTN_ALLARTICLE')" :icon="['fas', 'chevron-right']" :to="'/video'" class="w-100" />
                    </el-col>
                    <el-col :span="12">
                        <div class="VideoCarouselMobileArrowBtns">
                            <el-button @click="VideoCarouselMobileHandler('left')">
                                <font-awesome-icon :icon="['fas', 'chevron-left']" />
                            </el-button>
                            <el-button @click="VideoCarouselMobileHandler('right')">
                                <font-awesome-icon :icon="['fas', 'chevron-right']" />
                            </el-button>
                        </div>
                    </el-col>
                </el-row>
            </div>
        </div>
    </div>
</template>
<style lang="scss" scope>
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
.SectionVideo {
    background: left bottom / contain no-repeat url("@/assets/images/home/bg_white.svg"), $color-primary-black;
    padding: 120px 0;
    .SectionHeader {
        display: flex;
        justify-content: space-between;
        align-items: baseline;
        @media screen and (max-width: $Tab) {
            justify-content: center;
            flex-wrap: wrap;
        }
        .Title {
            display: flex;
            align-items: baseline;
            margin-bottom: 50px;
            @media screen and (max-width: $Tab) {
                margin-bottom: 30px;
            }
            .SectionTitle {
                line-height: 0.7;
                font-size: 3.75rem;
                color: white;
                font-weight: 200;
            @media screen and (max-width: $Tab) {
                margin-right: 12px;
            }
                .icon {
                    margin-right: 16px;
                }
            }
            .SectionSubTitle {
                font-size: 1rem;
                margin-left: 20px;
                color: white;
                @media screen and (max-width: $Tab) {
                    margin-left: 0;
                }
                .icon {
                    margin-right: 2px;
                    margin-bottom: 3px;
                }
            }
            .NewsCategoryBtn {
                height: 48px;
                padding: 0 40px;
                font-size: 1rem;
                border-color: $color-black-33;
                color: $color-black;
                border-radius: 30px;
                &.active {
                    background-color: $color-primary-black;
                    color: white;
                }
                &:not(.active):hover{
                    background-color: white;
                    color: $color-primary;
                }
            }
        }
        .TitleTools {
            color: $color-primary;
            font-size: 1rem;
            @media screen and (max-width: $Tab) {
                width: 100%;
                margin-bottom: 20px;
            }
        }
    }
    .VideoCarousel {
        position: relative;
        .VideoCarouselContent {
            position: relative;
            border-radius: 10px;
            background-color: white;
            height: 390px;
            overflow: hidden;
            .VideoCarouselContentBox {
                position: absolute;
                padding: 38px 167px 38px 60px;
                top: 0;
                left: 0;
                &.FadeOut {
                    opacity: 0;
                    transition: opacity 1s;
                }
                &.FadeIn {
                    opacity: 1;
                    transition: opacity 1s;
                }
                .Tag {
                    margin-bottom: 20px;
                    span {
                        font-size: 1rem;
                        padding: 7px 25px;
                        border-radius: 10px;
                        color: white;
                        background-color: $color-primary-black;
                    }
                }
                .Title {
                    margin-bottom: 12px;
                    color: $color-deep-black;
                    font-size: 2rem;
                }
                .Content {
                    margin-bottom: 20px;
                    color: $color-gray;
                    font-size: 1rem;
                }
                .Info {
                    font-size: 0.875rem;
                    color: $color-gray;
                    .el-tag {
                        font-size: inherit;
                        margin-right: 20px;
                        color: $color-primary;
                        border-color: $color-primary;
                    }
                }
            }
            &:hover {
                background-color: $color-primary;
                .VideoCarouselContentBox{
                    .Tag {
                        span {
                            background-color: white;
                            color: $color-primary;
                        }
                    }
                    .Title, .Content {
                        color: white;
                    }
                    .Info {
                        color: white;
                        .el-tag {
                            color: white;
                            background-color: transparent;
                            border-color: white;
                        }
                    }
                }
            }
        }
        .VideoCarouselVideo {
            position: relative;
            height: 390px;
            .VideoCarouselVideoBox {
                position: absolute;
                width: 130%;
                max-width: 767px;
                border-radius: 10px;
                overflow: hidden;
                height: 130%;
                max-height: 430px;
                right: 0;
                bottom: 76px;
                .Video {
                    width: 100%;
                    height: 100%;
                    &.FadeOut {
                        animation: fadeOut 1s ease-out forwards;
                    }
                    &.FadeIn {
                        animation: fadeIn 1s ease-in forwards;
                    }
                }
            }
            .VideoCarouselSpot {
                position: absolute;
                right: 0;
                bottom: 0;
                .SpotList {
                    display: flex;
                    .SpotItem {
                        margin: 0 12px;
                        .Spot {
                            display: block;
                            width: 7px;
                            height: 7px;
                            border: none;
                            border-radius: 50%;
                            background-color: white;
                            opacity: 0.3;
                            padding: 0;
                            &.active {
                                opacity: 1;
                            }
                        }
                    }
                }
            }
        }
        .ArrowBtn {
            border: 0;
            width: 56px;
            height: 56px;
            position: absolute;
            top: 50%;
            z-index: 2;
            &.Left {
                transform: translate(-100%, -100%);
                left: -30px;
            }
            &.Right {
                transform: translate(100%, -100%);
                right: -30px;
            }
            &:hover {
                color: white;
                background-color: $color-primary;
            }
        }
    }
    .VideoList {
        margin: 60px 0;
    }
    .BottomRow {
        margin-top: 50px;
    }
    .VideoCarouselMobile {
        background-color: white;
        border-radius: 10px;
        padding-top: 20px;
        .VideoCarouselMobileContent {
            padding: 20px;
            .VideoCarouselMobileContentBox {
                .Tag {
                    margin-bottom: 20px;
                    span {
                        font-size: 1rem;
                        padding: 7px 25px;
                        border-radius: 10px;
                        color: white;
                        background-color: $color-primary-black;
                    }
                }
                .Title {
                    margin-bottom: 12px;
                    color: $color-deep-black;
                    font-size: 1rem;
                    font-weight: 500;
                }
                .Content {
                    margin-bottom: 20px;
                    color: $color-gray;
                    font-size: 1rem;
                    font-weight: 400;
                }
                .Info {
                    font-size: 0.875rem;
                    color: $color-gray;
                    .el-tag {
                        margin-right: 20px;
                        color: $color-primary;
                        border-color: $color-primary;
                    }
                }
            }
        }
        .VideoCarouselMobileVideo {
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 4px auto 0;
            padding: 20px 20px 16px 20px;
            height: 50%;
            max-height: 240px;
            .Video {
                aspect-ratio: 100 / 56;
                border-radius: 10px;
                overflow: hidden;
            }
        }
        .el-carousel__indicators.el-carousel__indicators--horizontal {
			bottom: 8px;
			.el-carousel__button {
				width: 10px;
				height: 10px !important;
				border-radius: 50%;
                background-color: $color-carousel-spot-bg;
                margin-right: 20px;
			}
            .is-active {
                .el-carousel__button {
                    background-color: $color-carousel-spot-bg-active;
                }
            }
		}
    }
    .VideoCarouselMobileArrowBtns {
        display: flex;
        justify-content: end;
        align-items: center;
        .el-button {
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 50%;
            height: 48px;
            width: 48px;
            box-shadow: 0px 4px 10px 0px ;
        }
    }
}
</style>
<script setup>
import { reactive, defineProps, watch, onBeforeMount, h, getCurrentInstance } from "vue";
import BasePageLayout from '@/components/Base/BasePageLayout/Index.vue';
import BaseTagList from '@/components/Base/BaseTagList.vue';
import BasePagination from '@/components/Base/BasePagination.vue';
import { usePublicInfoStore } from '@/stores/public-info';

const { proxy } = getCurrentInstance();

const publicInfoStore = usePublicInfoStore()
const publicInfoData = publicInfoStore.data

const props = defineProps({
    hash: {
        type: String
    }
})
const currentData = reactive({
    dataList: publicInfoData.list
})
const columns = reactive([
    {
        key: "title",
        dataKey: "title",
        title: "名稱",
        width: "480px"
    },
    {
        key: "publish_up",
        dataKey: "publish_up",
        title: "發布日期",
        width: "240px",
        cellRenderer: ({ cellData: publish_up }) => h(
            'div',
            { textContent: proxy.$formater.dateFormate(publish_up, "Y/M/D")  }
        )
    },
    {
        key: "params",
        dataKey: "params",
        title: "附件",
        width: "280px",
        cellRenderer: ({ cellData: params }) => h (
            BaseTagList,
            { list: getDownloadList(params.attachments) }
        )
    }
])

watch(props, () => {
    publicInfoData.categoryList.menuData.active = props.hash.replace("#", "")
    publicInfoStore.initList()
})

onBeforeMount(() => {
    publicInfoData.categoryList.menuData.active = props.hash? props.hash.replace("#", ""):"regulations"
    publicInfoStore.initData()
})

const getDownloadList = (originArr) => {
    if(!Array.isArray(originArr)) {
        originArr = [originArr]
    }
    let newArr = []
    originArr.forEach((item) => {
        let itemArr = item.split(".")
        if(itemArr.length > 2) {
            const tempArr = [ "", "" ]
            itemArr.forEach((item, index) => {
                if(index < itemArr.length - 1) {
                    tempArr[0] += item
                    if(index < itemArr.length - 2) {
                        tempArr[0] += "."
                    }
                } else {
                    tempArr[1] = item
                }
            })
            itemArr = tempArr
        }
        let newArrItem = {
            text: itemArr[0].split("/").length > 0? itemArr[0].split("/")[itemArr[0].split("/").length - 1]:itemArr[0],
            type: itemArr[1],
            href: item
        }
        newArr.push(newArrItem)
    })
    return newArr
}
</script>
<template>
    <BasePageLayout :BannerData="publicInfoData.bannerData" :BreadcrumbData="publicInfoData.breadcrumbData" :AsideData="publicInfoData.categoryList" bgColor="defaultO4">
        <div class="PublicInfo">
            <span id="Regulation" class="anchor"></span>
            <span id="Methods" class="anchor"></span>
            <span id="Plan" class="anchor"></span>
            <span id="Budget" class="anchor"></span>
            <span id="Download" class="anchor"></span>
            <span id="Purchase" class="anchor"></span>
            <span id="Other" class="anchor"></span>
            <div class="content">
                <div class="subTitle">{{ publicInfoData.subTitleData.text }}<span class="primary">{{ publicInfoData.subTitleData.textEn }}</span></div>
                <div v-if="publicInfoData.list.length > 0" class="infoList">
                    <div class="d-none d-lg-block" style="height: 550px;">
                        <el-auto-resizer>
                            <template #default="{ height, width }">
                                <el-table-v2 
                                    :columns="columns" 
                                    :data="publicInfoData.list" 
                                    :width="width" 
                                    :height="height"
                                    header-class="TableRow"
                                    row-class="TableRow"
                                    :row-height="64"
                                ></el-table-v2>
                            </template>
                        </el-auto-resizer>
                    </div>
                    <!-- <el-table class="d-none d-lg-block" :data="currentData.dataList" highlight-current-row cell-class-name="TableCell" header-cell-class-name="TableCell" row-class-name="TableRow" scope="row">
                        <el-table-column prop="name" label="名稱" />
                        <el-table-column prop="date" label="發布日期" />
                        <el-table-column label="附件">
                            <template #default="scope">
                                <BaseTagList :list="getDownloadList(scope.row.downloadUrls)" listType="download" />
                            </template>
                        </el-table-column>
                        <el-table-column prop="downloadTime" label="下載次數" />
                    </el-table> -->
                    <ul class="DataList d-block d-lg-none">
                        <li v-for="(item, index) in currentData.dataList" :key="index">
                            <div>
                                <div class="title">{{ item.name }}</div>
                                <div class="date">{{ item.date }}</div>
                            </div>
                            <div class="TagList">
                                <BaseTagList :list="getDownloadList(item.downloadUrls)" listType="download" />
                            </div>
                            <!-- <div class="date">下載次數:<span>{{ item.downloadTime }}</span></div> -->
                        </li>
                    </ul>
                    <div class="Pagination">
                        <div class="container">
                            <div class="content">
                                <BasePagination position="center" :layout="['go-first', 'prev', 'pager', 'next', 'go-last']" :total="currentData.dataList.length" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </BasePageLayout>
</template>
<style lang="scss" scope>
.PublicInfo {
    padding: 160px 0 60px 133px;
    @media screen and (max-width: $Tab) {
        padding: 30px 20px 50px;
    }
    .content {
        width: 100%;
        max-width: 870px;
        .subTitle {
            margin-bottom: 50px;
            font-size: 1.25rem;
            @media screen and (max-width: $Tab) {
                margin-bottom: 30px;
                font-size: 1rem;
            }
            span {
                margin-left: 14px;
                &.primary {
                    color: $color-primary;
                }
            }
        }
        .infoList {
            .el-table {
                font-size: 1rem;
            }
            .TableCell {
                border: none;
                padding: 34px 20px;
            }
            .TableRow {
                border: none;
                font-size: 1rem;
            }
            .DataList {
                padding: 25px 20px;
                margin: 0;
                li {
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    min-height: 190px;
                    &:not(:last-child) {
                        margin-bottom: 70px;
                    }
                    .title {
                        margin-bottom: 10px;
                        font-weight: 500;
                    }
                    .date {
                        font-size: 0.875rem;
                        color: $color-gray;
                    }
                }
            }
            .Pagination {
                padding: 60px 0;
                @media screen and (max-width: $Tab) {
                    padding: 80px 0 0;
                }
                .content {
                    display: flex;
                    justify-content: center;
                    .el-pager {
                        li {
                            font-size: 0.875rem;
                            &.is-active {
                                background-color: $color-primary-black;
                                color: white;
                                border-radius: 5px;
                            }
                        }
                    }
                }
            }
        }
    }
}
.anchor {
    position: absolute;
    top: -135px;
    @media screen and (max-width: $MD) {
        top: -70px
    }
}
</style>
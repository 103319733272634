<script setup>
import { defineProps } from 'vue'

const props = defineProps({
    ItemData: {
        type: Array,
        required: true,
        default() {
            return []
        }
    }
})
</script>
<template>
    <div class="SectionLinksItem">
        <el-row :gutter="20">
            <el-col v-for="link in props.ItemData" :key="link" :sm="6" :xs="12" class="linksItem">
                <a v-if="link.extrafields" :href="link.extrafields.link ? link.extrafields.link.value : '#'">
                    <img v-if="link.image" :src="link.image" :alt="link.title" >
                    <img v-else-if="link.introimage" :src="link.introimage" :alt="link.title" >
                    <img v-else src="@/assets/images/home/links_logo.svg" :alt="link.title" >
                </a>
            </el-col>
        </el-row>
    </div>
</template>
<style lang="scss" scope>
.SectionLinksItem {
    .linksItem {
        margin-bottom: 20px;
        a {
            background-color: white;
            padding: 18px 22px;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: 100%;
            img {
                width: 100%;
            }
        }
    }
}
</style>
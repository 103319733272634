<script setup>
import { useGlobalStore } from '@/stores/global';

const globalStore = useGlobalStore()
// const globalData = globalStore.data
const openLink = (Url) => {
    if(Url) {
        window.open(Url)
    }
}
</script>
<template>
    <footer>
        <div class="FooterDecoration"></div>
        <div class="container">
            <div class="FooterContent">
                <div class="LeftFooterContent">
                    <div class="Info">
                        <img v-if="$i18n.locale === 'zh-TW'" class="logo" src="@/assets/images/footer/logo.svg" alt="">
                        <img v-else class="logo" src="@/assets/images/footer/logo_en.svg" alt="">
                        <ul class="InfoList">
                            <li>
                                <span class="label">{{ $t("FOOTER_INFO_ADDRESS_LABEL") }}</span><span>{{ $t("FOOTER_INFO_ADDRESS") }}</span>
                            </li>
                            <li>
                                <span class="label">{{ $t("FOOTER_INFO_PHONE_LABEL") }}</span><span>{{ $t("FOOTER_INFO_PHONE") }}</span>
                            </li>
                            <li>
                                <span class="label">{{ $t("FOOTER_INFO_SERVICETIME_LABEL") }}</span><span>{{ $t("FOOTER_INFO_SERVICETIME") }}</span>
                            </li>
                            <li>
                                <span class="label">{{ $t("FOOTER_INFO_SERVICEMAIL_LABEL") }}</span><span><a href="mailto:service@tiss.org.tw">{{ $t("FOOTER_INFO_SERVICEMAIL") }}</a></span>
                            </li>
                        </ul>
                    </div>
                    <ul class="SocialList d-none d-lg-flex">
                        <li>
                            <!-- <a href="https://www.facebook.com/profile.php?id=61553673834147" target="_blank" title="FB粉絲團">
                                <svg class="svg-inline--fa fa-facebook-f" title="fb-icon" alt="fb-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path d="M80 299.3V512H196V299.3h86.5l18-97.8H196V166.9c0-51.7 20.3-71.5 72.7-71.5c16.3 0 29.4 .4 37 1.2V7.9C291.4 4 256.4 0 236.2 0C129.3 0 80 50.5 80 159.4v42.1H14v97.8H80z"/></svg>
                            </a> -->
                            <div class="SocialLink" @click="openLink('https://www.facebook.com/profile.php?id=61553673834147')">
                                <font-awesome-icon :icon="['fab', 'facebook-f']" />
                            </div>
                        </li>
                        <!-- <li>
                            <a href=""><img src="@/assets/images/footer/line_icon.svg" alt=""></a>
                        </li> -->
                        <!-- <li>
                            <a href=""><font-awesome-icon :icon="['fab', 'x-twitter']" /></a>
                        </li> -->
                    </ul>
                </div>
                <div class="RightFooterContent">
                    <el-row :gutter="15" style="width: 100%;">
                        <el-col v-for="(item, index) in globalStore.footerList" :key="index" :sm="4" :xs="8">
                            <RouterLink class="MenuTitle" :to="item.to">{{ $t(item.textKey) }}</RouterLink>
                            <ul v-if="item.childrenList" class="MenuList">
                                <li v-for="(_item, _index) in item.childrenList" :key="_index">
                                    <a :href="_item.to">{{ _item.text? _item.text : $t(_item.textKey) }}</a>
                                </li>
                            </ul>
                        </el-col>
                    </el-row>
                    <ul class="SocialList d-block d-lg-none">
                        <li>
                            <!-- <a href="https://www.facebook.com/profile.php?id=61553673834147" target="_blank" title="FB粉絲團">
                                <svg class="svg-inline--fa fa-facebook-f" title="fb-icon" alt="fb-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path d="M80 299.3V512H196V299.3h86.5l18-97.8H196V166.9c0-51.7 20.3-71.5 72.7-71.5c16.3 0 29.4 .4 37 1.2V7.9C291.4 4 256.4 0 236.2 0C129.3 0 80 50.5 80 159.4v42.1H14v97.8H80z"/></svg>
                            </a> -->
                            <div class="SocialLink" @click="openLink('https://www.facebook.com/profile.php?id=61553673834147')">
                                <font-awesome-icon :icon="['fab', 'facebook-f']" />
                            </div>
                        </li>
                    </ul>
                    <div class="d-flex w-100">
                        <span>{{ $t("FOOTER_SUGGEST") }}</span>
                        <!-- <a href="https://accessibility.moda.gov.tw/Applications/Detail?category=20211129144351" title="無障礙網站另開新視窗" target="_blank" class="ml-4">
                            <img src="@/assets/images/accessibilityA.png" alt="通過A檢測等級無障礙網頁檢測" border="0" width="88" height="31" />
                        </a>     -->
                    </div>
                </div>
            </div>
        </div>
        <div class="copyright">
            <div class="container">{{ $t("COPYRIGHT") }}</div>
        </div>
    </footer>
</template>
<style lang="scss" scope>
    footer {
        background-image: url("@/assets/images/footer/bg_img.jpg");
        font-size: 0.875rem;
        .FooterDecoration {
            width: 100%;
            height: 6px;
            background: linear-gradient(to right, #00A886, #77CBF2);
        }
        .FooterContent {
            display: flex;
            justify-content: space-between;
            padding: 40px 0;
            @media screen and (max-width: $Tab) {
                display: block;
                padding: 40px 28px;
            }
            .LeftFooterContent {
                display: flex;
                flex-wrap: wrap;
                align-content: space-between;
                width: 40%;
                max-width: 430px;
                height: 353px; 
                @media screen and (max-width: $Tab) {
                    width: 100%;
                    max-width: none;
                    height: auto;
                }
                .Info {
                    width: 430px;
                    .logo {
                        max-width: 425px;
                        width: 100%;
                        max-width: 360px;
                    }
                    .InfoList {
                        margin-top: 14px;
                        list-style: none;
                        padding: 0;
                        li {
                            display: flex;
                            margin: 7px 0;
                            .label {
                                position: relative;
                                padding-right: 8px;
                                display: flex;
                                align-items: center;
                                &::after {
                                    content: "";
                                    position: absolute;
                                    width: 1px;
                                    height: 14px;
                                    background-color: $color-black;
                                    transform: translateY(-50%);
                                    right: 0;
                                    top: 50%;
                                }
                            }
                            span + span {
                                padding-left: 8px;
                            }
                        }
                    }
                }
            }
            .RightFooterContent {
                display: flex;
                flex-wrap: wrap;
                align-content: space-between;
                // justify-content: flex-end;
                width: 100%;
                // max-width: 680px;
                height: 353px;
                @media screen and (max-width: $Tab) {
                    width: 100%;
                    max-width: none;
                    margin-top: 40px;
                    justify-content: flex-start;
                    height: auto;
                }
                .MenuTitle {
                    font-size: 1.25rem;
                    font-weight: 500;
                    margin-bottom: 30px;
                    color: $color-black;
                }
                .MenuList {
                    margin-top: 14px;
                    list-style: none;
                    padding: 0;
                    @media screen and (max-width: $Tab) {
                        margin-bottom: 30px;
                    }
                    li {
                        margin: 10px 0;
                    }
                    a {
                        color: $color-black;
                    }
                }
            }
        }
        .SocialList {
            // display: none !important; //之後要用在打開
            list-style: none;
            padding: 0;
            li {
                margin: 0 10px;
            }
            a, .SocialLink {
                color: $color-primary-black;
                display: flex;
                align-items: center;
                justify-content: center;
                width: 48px;
                height: 48px;
                background-color: $color-icon-bg-gray;
                border-radius: 50%;
                cursor: pointer;
            }
        }
        .copyright {
            border-top: 1px solid $color-light-gray;
            padding: 30px 0;
            @media screen and (max-width: $Tab) {
                padding: 40px 28px;
            }
        }
    }
</style>
<script setup>
// import { ref } from 'vue';
import { useGlobalStore } from '@/stores/global'
import { onMounted } from 'vue';

import { useI18n } from "vue-i18n";
const { locale } = useI18n();

const globalStore = useGlobalStore()
const globalData = globalStore.data

const fontsize_list = [
    {
        value: 14,
        text: "小",
        text_en: 'A<sup>-</sup>',
    },
    {
        value: 16,
        text: "中",
        text_en: 'A<sup>&nbsp;</sup>',
    },
    {
        value: 18,
        text: "大",
        text_en: 'A<sup>+</sup>',
    },
]

onMounted(() => {
    if(!localStorage.getItem("lang")) {
        localStorage.setItem("lang", "zh-Hant")
    } else {
        if(localStorage.getItem("lang") === "zh-Hant") {
            locale.value = "zh-TW"
        } else {
            locale.value = "en"
        }
    }
})

const setLang = () => {
    if(locale.value === "zh-TW") {
        locale.value = "en"
        localStorage.setItem("lang", locale.value)
    } else {
        locale.value = "zh-TW"
        localStorage.setItem("lang", "zh-Hant")
    }
    console.log(window.location)
    window.location.href =  window.location.origin + window.location.pathname + "?lang=" + localStorage.getItem('lang')
}
const setFontSize = (value) => {
    if(value) {
        globalData.fontSize = value
        localStorage.setItem("fontSize", value)
    }
}
// const isShowSearch = ref(false)
// const showSearch = () => {
//     isShowSearch.value = !isShowSearch.value
// }
</script>
<template>
    <div class="logoNav">
        <div class="container">
            <RouterLink class="mainLogo" to="/" @click="globalData.isShowMenu = false">
                <img v-if="$i18n.locale === 'zh-TW'"
                    src="@/assets/images/header/logo.svg"
                    alt="TISS logo"
                />
                <img v-else
                    src="@/assets/images/header/logo_en.svg"
                    alt="TISS logo"
                />
            </RouterLink>
            <nav class="d-lg-flex d-none">
                <ul class="ToolList">
                    <!-- <li>
                        <RouterLink to="/sitemap">
                            <el-button text>網站導覽</el-button>
                        </RouterLink>
                        <el-button text>常見問答</el-button>
                    </li> -->
                    <li>
                        <el-button v-for="(btn, index) in fontsize_list" :key="index" class="FontSizeBtn" :class="{ 'active': globalData.fontSize === btn.value }" text circle plain v-html="$i18n.locale === 'zh-TW'? btn.text:btn.text_en" @click="setFontSize(btn.value)"></el-button>
                    </li>
                    <li>
                        <el-button class="LangBtn" round plain @click="setLang()">
                            <font-awesome-icon :icon="['fas', 'globe']" />
                            <span>{{ $i18n.locale === "zh-TW"? "En":"中文" }}</span>
                        </el-button>
                        <!-- <el-button class="SearchBtn d-none" :class="{ 'active': isShowSearch }" text circle plain @click="showSearch()"><font-awesome-icon :icon="['fas', 'magnifying-glass']" /></el-button>
                        <div v-show="isShowSearch" class="SearchKeywordInput">
                            <el-input :placeholder="$t('INPUT_SEARCH_KEYWORD_PLACEHOLDER')"></el-input>
                        </div> -->
                    </li>
                </ul>
            </nav>
        </div>
    </div>
</template>
<style lang="scss" scoped>
    .logoNav {
        background-color: white;
        position: relative;
        min-height: 48px;
        @media screen and (max-width: $Tab) {
            min-height: 0;
        }
        z-index: 2;
        .mainLogo {
            display: flex;
            align-items: center;
            background-image: url('@/assets/images/header/nav_shape.svg');
            background-repeat: no-repeat;
            background-size: contain;
            background-position: 0 calc(100% - 2px);
            position: absolute;
            left: 0;
            top: 0;
            padding: 48px 110px 25px 44px;
            border-radius: 0 0 24px 0;
            z-index: 2;
            height: 100%;
            min-height: 140px;
            filter: drop-shadow(0px 4px 15px $color-main-logo-shadow);
            img {
                object-fit: fill;
                width: 100%;
                max-width: 360px;
                min-height: 66px;
            }
            @media screen and (max-width: $PC) {
                min-height: 127px;
                img {
                    max-width: 270px;
                    background-image: url('@/assets/images/header/nav_shape.svg');

                }
            }
            @media screen and (max-width: $Tab) {
                background-image: url('@/assets/images/header/nav_shape_m.svg');
                background-position: 0 0;
                padding: 20px 106px 23px 15px;
                min-height: 70px;
                img {
                    max-width: 212px;
                }
            }
        }
        nav {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            padding: 14px 0;
            .label {
                font-size: 1rem;
                color: #717171;
            }
            .ToolList {
                display: flex;
                margin: 0;
                li {
                    display: flex;
                    align-items: center;
                    padding: 0 16px;
                    .FontSizeBtn {
                        &.active {
                            background-color: $color-light-primary;
                        }
                    }
                    .LangBtn {
                        border-color: $color-black;
                        span {
                            margin-left: 5px;
                        }
                        &:hover, &:active {
                            color: white;
                            background-color: $color-primary;
                            border-color: $color-primary;
                        }
                    }
                    .SearchBtn {
                        background-color: $color-light-primary;
                        &.active {
                            color: white;
                            background-color: $color-primary;
                            border-color: $color-primary;
                        }
                    }
                    .SearchKeywordInput {
                        margin-left: 14px;
                    }
                }
                li:not(:last-child) {
                    border-right: 1px solid $color-dash-border;
                }
            }
        }
    }
</style>
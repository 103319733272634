<script setup>
// import { onMounted } from "vue";

import BasePageLayout from '@/components/Base/BasePageLayout/Index.vue';
import { useGlobalStore } from '@/stores/global';
import { useSitemapStore } from '@/stores/sitemap';

const globalStore = useGlobalStore()
const globalData = globalStore.data
const sitemapStore = useSitemapStore()
const sitemapData = sitemapStore.data
</script>
<template>
<BasePageLayout :BannerData="sitemapData.bannerData" :BreadcrumbData="sitemapData.breadcrumbData" bgColor="defaultO4">
    <div class="Sitemap">
        <div class="content">
            <div class="Title">導覽介紹</div>
            <p>
                本網站依無障礙網頁設計原則建置，網站的主要內容分為三大區塊：<br>
                1. 上方功能區塊、2. 中央內容區塊、3.下方功能區塊<br>
                本網站的快速鍵﹝Accesskey﹞設定如下：<br>
                <ul>
                    <li>Alt+U：上方功能區塊，包括回首頁、網站導覽、網站搜尋等。</li>
                    <li>Alt+C：中央內容區塊，為本頁主要內容區。</li>
                    <li>Alt+B：下方內容區塊，為本頁其他資訊區塊</li>
                    <li>Alt+S: 全站文章搜尋</li>
                </ul>
                ※當本網站項目頁籤無法以滑鼠點選時，您可利用以下鍵盤操作方式瀏覽資料 Tab：停留於該標籤後,可利用Tab鍵跳至內容瀏覽該筆資料，遇到radio按鈕時請配合使← → or↑↓鍵移動項目順序。Tab + Shift：按Tab + Shift可往回跳至上一筆資料；當跳回至標籤項目時您可繼續利用← → or↑↓鍵移動標籤順序。<br>
                各瀏覽器快速鍵操作說明：<br>
                <ul>
                    <li>Internet Explorer 請使用Alt加上快速鍵代碼操作。</li>
                    <li>Firefox 請使用Alt+Shift加上快速鍵代碼操作。</li>
                    <li>Google Chrome請使用Alt加上快速鍵代碼操作。</li>
                    <li>Safari 請使用Alt加上快速鍵代碼操作。</li>
                </ul>
                若為作業系統為Mac則使用Alt+Control加上快速鍵代碼操作。<br>
            </p>
            <div class="LinkList">
                <div class="contentBox">
                    <el-row :gutter="70">
                        <el-col v-for="(item, index) in globalData.linkList" :key="index" :lg="6" :xs="12" class="LinkItem">
                            <RouterLink class="MenuTitle" to="/">{{ $t(item.textKey) }}</RouterLink>
                            <ul v-if="item.childrenList" class="MenuList">
                                <li v-for="(_item, _index) in item.childrenList" :key="_index">
                                    <a :href="_item.to">{{ $t(_item.textKey) }}</a>
                                </li>
                            </ul>
                        </el-col>
                    </el-row>
                </div>
            </div>
        </div>
    </div>
</BasePageLayout>
</template>
<style lang="scss" scope>
.Sitemap {
    padding: 160px 0 60px 133px;
    width: 100%;
    max-width: 1332px;
    @media screen and (max-width: $Tab) {
        padding: 30px 20px 50px;
    }
    .content {
        width: 100%;
        .Title {
            font-size: 2rem;
            margin-bottom: 20px;
        }
        p {
            line-height: 2;
            font-size: 1.125rem;
            margin-bottom: 60px;
            max-width: 700px;
            ul {
                margin: 0;
                li {
                    list-style-type: disc;
                }
            }
        }
        .LinkList {
            background-color: white;
            border-radius: 10px;
            padding: 60px;
            box-shadow: 0px 3px 8px $color-btn-shadow;
            @media screen and (max-width: $Tab) {
                padding: 20px;
            }
            .contentBox {
                max-width: 918px;
                .MenuTitle {
                    display: block;
                    font-size: 1.25rem;
                    font-weight: 500;
                    border-bottom: 1px solid $color-dash-border;
                    padding-bottom: 10px;
                    color: $color-black;
                }
                .LinkItem {
                    margin-bottom: 60px;
                    .MenuList {
                        padding: 16px 0 0 0;
                        
                        li {
                            font-size: 1rem;
                            margin-bottom: 10px;
                            a {
                                color: $color-black;
                            }
                        }
                    }     
                }
            }
        }
    }
    
}
</style>
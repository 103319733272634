import { createApp } from 'vue'
import { createMetaManager } from 'vue-meta'
import { createPinia } from 'pinia'

/* import the fontawesome core */
import { library } from '@fortawesome/fontawesome-svg-core'
/* import font awesome icon component */
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

import { faChevronRight as fasChevronRight } from '@fortawesome/free-solid-svg-icons'
import { faChevronLeft as fasChevronLeft } from '@fortawesome/free-solid-svg-icons'
import { faChevronDown as fasChevronDown } from '@fortawesome/free-solid-svg-icons'
import { faChevronUp as fasChevronUp } from '@fortawesome/free-solid-svg-icons'
import { faAnglesLeft as fasAnglesLeft } from '@fortawesome/free-solid-svg-icons'
import { faAnglesRight as fasAnglesRight } from '@fortawesome/free-solid-svg-icons'
import { faBars as fasBars } from '@fortawesome/free-solid-svg-icons'
import { faXmark as fasXmark } from '@fortawesome/free-solid-svg-icons'
import { faPlus as fasPlus } from '@fortawesome/free-solid-svg-icons'
import { faMinus as fasMinus } from '@fortawesome/free-solid-svg-icons'
import { faGlobe as fasGlobe } from '@fortawesome/free-solid-svg-icons'
import { faMagnifyingGlass as fasMagnifyingGlass } from '@fortawesome/free-solid-svg-icons'

import { faFlag as farFlag } from '@fortawesome/free-regular-svg-icons'
import { faEye as farEye } from '@fortawesome/free-regular-svg-icons'

import { faFacebookF as fabFacebookF } from '@fortawesome/free-brands-svg-icons'
import { faLine as fabLine } from '@fortawesome/free-brands-svg-icons'
import { faXTwitter as fabXTwitter } from '@fortawesome/free-brands-svg-icons'

library.add(fasChevronRight, fasChevronLeft, fasChevronUp, fasAnglesLeft, fasAnglesRight, fabFacebookF, fabLine, fabXTwitter, fasBars, fasXmark, fasPlus, fasMinus, fasGlobe, fasMagnifyingGlass, fasChevronDown, farFlag, farEye)

import 'bootstrap/dist/css/bootstrap.min.css'
import "bootstrap"

import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'

import App from './App.vue'
import router from './router'

import { i18n } from "@/lang"

import { formater } from "@/utils/formater"
import { facebook } from "@/utils/facebook"

const app = createApp(App);

// 設定自訂全域功能
app.config.globalProperties.$formater = formater
app.config.globalProperties.$facebook = facebook

app.use(createPinia()).component('font-awesome-icon', FontAwesomeIcon).use(ElementPlus).use(router).use(i18n).use(createMetaManager(false, {
    meta: { tag: 'meta', nameless: true }
}));
await router.isReady()
app.mount('#app');

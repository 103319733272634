import ApiItem from "./item.js"
import ApiModule from "./module.js"
import ApiCategory from "./category.js"
import ApiMenu from "./menu.js"

const ApiCollects = [
    ...ApiItem,
    ...ApiModule,
    ...ApiCategory,
    ...ApiMenu
]
export default ApiCollects
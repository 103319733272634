import { createRouter, createWebHistory } from 'vue-router'
import { useAnnouncementStore } from '@/stores/announcement';
import { useVideoStore } from '@/stores/video';
import { useResearchStore } from '@/stores/research';

import HomeView from '@/views/Home/Index.vue'
import SitemapView from '@/views/Sitemap/Index.vue'
import AnnouncementView from '@/views/Announcement/Index.vue'
import AnnouncementPageView from '@/views/Announcement/Page/Index.vue'
import VideoView from '@/views/Video/Index.vue'
import VideoPageView from '@/views/Video/Page/Index.vue'
import AboutView from '@/views/About/Index.vue'
// import UnitView from '@/views/Unit/Index.vue'
import ResearchView from '@/views/Research/Index.vue'
import ResearchPageView from '@/views/Research/Page/Index.vue'
import PublicInfoView from '@/views/PublicInfo/Index.vue'
import ComingSoonView from '@/views/ComingSoon.vue'

const routes = [
  { path: '/:pathMatch(.*)*', redirect: "/" },
  {
    path: '/',
    name: 'home',
    component: HomeView,
  },
  {
    path: '/sitemap',
    name: 'sitemap',
    component: SitemapView
  },
  {
    path: '/announcement',
    name: 'announcement',
    component: AnnouncementView,
    props: (route) => ({
      hash: route.hash.indexOf("?") > -1? route.hash.split("?")[0]:route.hash,
    }),
  },
  {
    path: '/announcement/:alias',
    name: 'announcement-page',
    component: AnnouncementPageView,
    meta: {
      async beforeResolve(routeTo, routeFrom, next) {
        if(!routeTo.params.alias) {
          next({ name: "announcement" })
        } else {
          await useAnnouncementStore().initPageData(routeTo.params.alias)
        }
      }
    }
  },
  {
    path: '/video',
    name: 'video',
    component: VideoView,
    props: (route) => ({
      hash: route.hash.indexOf("?") > -1? route.hash.split("?")[0]:route.hash,
    })
  },
  {
    path: '/video/:alias',
    name: 'video-page',
    component: VideoPageView,
    meta: {
      async beforeResolve(routeTo, routeFrom, next) {
        if(!routeTo.params.alias) {
          next({ name: "video" })
        } else {
          await useVideoStore().initPageData(routeTo.params.alias)
        }
      }
    }
  },
  {
    path: '/about',
    name: 'about',
    component: AboutView,
    props: (route) => ({
      hash: route.hash.indexOf("?") > -1? route.hash.split("?")[0]:route.hash,
    }),
  },
  // {
  //   path: '/unit',
  //   name: 'unit',
  //   // redirect: "/coming-soon", //開發完畢後移除
  //   component: UnitView,
  //   props: (route) => ({
  //     hash: route.hash.indexOf("?") > -1? route.hash.split("?")[0]:route.hash,
  //   }),
  // },
  {
    path: '/research',
    name: 'research',
    // redirect: "/coming-soon", //開發完畢後移除
    component: ResearchView,
    props: (route) => ({
      hash: route.hash.indexOf("?") > -1? route.hash.split("?")[0]:route.hash,
    }),
  },
  {
    path: '/research/:alias',
    name: 'research-page',
    component: ResearchPageView,
    meta: {
      async beforeResolve(routeTo, routeFrom, next) {
        if(!routeTo.params.alias) {
          next({ name: "research" })
        } else {
          await useResearchStore().initPageData(routeTo.params.alias)
        }
      }
    }
  },
  {
    path: '/public-info',
    name: 'public-info',
    component: PublicInfoView,
    props: (route) => ({
      hash: route.hash.indexOf("?") > -1? route.hash.split("?")[0]:route.hash,
    })
  },
  {
    path: '/coming-soon',
    name: 'coming-soon',
    component: ComingSoonView
  },
]
const scrollBehavior = function (to) {
  if (to.hash) {
    return {
      selector: to.hash
    }
  }
}
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior
})

router.beforeEach(async (to, from, next) => {
  if (to.meta) {
    if(to.meta.beforeResolve) {
      await to.meta.beforeResolve(to, from, next)
    }
  }
  if(!to.query.lang && to.fullPath.indexOf("?lang=") < 0){
    to.fullPath += "?lang=" + localStorage.getItem("lang")
    to.href += "?lang=" + localStorage.getItem("lang")
    to.query.lang = localStorage.getItem("lang")
  }

  next()
})
router.afterEach(() => {
  document.body.scrollTop = 0; // For Safari
  document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
})

export default router

<script setup>
import { ref, defineProps, computed, onMounted } from 'vue'
import SectionLinksItem from "./SectionLinksItem.vue"

const props = defineProps({
    LinksList: {
        type: Array,
        required: true,
        default() {
            return [
                { url: "", imgUrl: "", text: "教育部體育署" }
            ]
        }
    }
})
const showCarouselSpot = ref("none")
const LinksList = computed(() => {
    let result = []
    for(let i=0;i<props.LinksList.length;i+=8) {
        result.push(props.LinksList.slice(i, i+8))
    }
    return result
})
const LinksListSm = computed(() => {
    let result = []
    for(let i=0;i<props.LinksList.length;i+=2) {
        result.push(props.LinksList.slice(i, i+2))
    }
    return result
})
onMounted(() => {
	setTimeout(() => {
		showCarouselSpot.value = ""
	}, 5000)
})
</script>
<template>
    <div id="links" class="SectionLinks">
        <div class="container">
            <div class="SectionHeader">
                <div class="Title">  
                    <div class="SectionTitle"><img v-if="$i18n.locale !== 'zh-TW'" class="icon" src="@/assets/images/icon/title_icon.svg" alt="">Links</div>
                    <div class="SectionSubTitle" v-if="$i18n.locale === 'zh-TW'">
                        <img class="icon" src="@/assets/images/icon/title_icon.svg" alt="">常用連結
                    </div>
                </div>
                <!-- <div class="TitleTools">
                    <span class="hint">[ {{ $t("HOME_NEWSHINT") }} ]</span>
                </div> -->
            </div>
            <div class="SectionLinksList">
                <el-carousel trigger="click" :autoplay="false" :indicator-position="showCarouselSpot" height="auto" arrow="never" class="d-none d-md-block">
                    <el-carousel-item v-for="item in LinksList" :key="item" style="height: 200px">
                        <SectionLinksItem :ItemData="item" />
                    </el-carousel-item>
                </el-carousel>
                <el-carousel trigger="click" :autoplay="false" :indicator-position="showCarouselSpot" height="auto" class="d-block d-md-none">
                    <el-carousel-item v-for="item in LinksListSm" :key="item" style="height: 70px">
                        <SectionLinksItem :ItemData="item" />
                    </el-carousel-item>
                </el-carousel>
            </div>
        </div>
    </div>
</template>
<style lang="scss">
.SectionLinks {
    background-color: $color-bg;
    padding: 120px 0;
    @media screen and (max-width: $Tab) {
        padding: 60px 0;
    }
    .SectionHeader {
        display: flex;
        justify-content: space-between;
        align-items: baseline;
        @media screen and (max-width: $Tab) {
            flex-wrap: wrap;
        }
        .Title {
            display: flex;
            align-items: baseline;
            margin-bottom: 50px;
            @media screen and (max-width: $Tab) {
                display: block;
                margin-bottom: 30px;
            }
            .SectionTitle {
                font-size: 3.75rem;
                color: $color-primary;
                font-weight: 200;
                .icon {
                    margin-right: 16px;
                }
            }
            .SectionSubTitle {
                font-size: 1rem;
                margin-left: 20px;
                @media screen and (max-width: $Tab) {
                    margin-left: 0;
                }
                .icon {
                    margin-right: 2px;
                    margin-bottom: 3px;
                }
            }
        }
        .TitleTools {
            color: $color-primary;
            font-size: 16px;
            @media screen and (max-width: $Tab) {
                width: 100%;
                margin-bottom: 20px;
            }
        }
    }
    .SectionLinksList {
        position: relative;
        .el-carousel {
            .el-carousel__indicators.el-carousel__indicators--horizontal {
                .el-carousel__button {
                    width: 6px;
                    height: 6px !important;
                    border-radius: 50%;
                    background-color: $color-primary;
                }
            }
        }
    }
}
</style>
import ApiCollects from "./apis"
const domain = window.location.hostname === "localhost"? "http://tiss.test/api":window.location.origin+"/api"

const request = {
    POST: async (apiName, requestData, params = [], option = {
        headers: { 'Content-Type': 'application/json' }
    }) => {
        try {
            const apiConfig = ApiCollects.find((item) => {
                return item.name === apiName
            })
            if(!apiConfig) {
                throw "undefined api. please add config in /utils/apis."
            } else if (apiConfig.method != "POST") {
                throw "this api's method is not POST."
            } else {
                const requestOptions = {
                    method: 'POST',
                    headers: option.headers,
                    body: requestData? JSON.stringify(requestData):""
                };

                let api = apiConfig.api
                if(api.indexOf(":*") > -1){
                    let idx = api.indexOf(":*");
                    let paramIndex = 0
                    while (idx !== -1) {
                        api = api.replace(":*", params[paramIndex])
                        paramIndex++
                        idx = api.indexOf(":*");
                    }
                }

                const response = await fetch(domain+api, requestOptions);

                const responseData = await response.json();
    
                return responseData
            }
        } catch (error) {
            console.error('Error fetching data: method POST ', error);
        }
    },
    GET: async (apiName, requestData, params = [], option = {
        headers: { 'Content-Type': 'application/json' }
    }) => {
        try {
            const apiConfig = ApiCollects.find((item) => {
                return item.name === apiName
            })
            if(!apiConfig) {
                throw "undefined api. please add config in /utils/apis."
            } else if (apiConfig.method != "GET") {
                throw "this api's method is not GET."
            } else {
                const requestOptions = {
                    method: 'GET',
                    headers: option.headers,
                };

                let api = apiConfig.api
                if(api.indexOf(":*") > -1){
                    let idx = api.indexOf(":*");
                    let paramIndex = 0
                    while (idx !== -1) {
                        api = api.replace(":*", params[paramIndex])
                        paramIndex++
                        idx = api.indexOf(":*");
                    }
                }
                let queryString = ""
                if(requestData) {
                    queryString = new URLSearchParams(requestData).toString();
                }

                const response = await fetch(domain+api+"?"+queryString, requestOptions);

                const responseData = await response.json();
    
                return responseData
            }
        } catch (error) {
            console.error('Error fetching data: method GET ', error);
        }
    }
}

export default request
import { ref, reactive } from 'vue'
import { defineStore } from 'pinia'
import { useApiMenuStore } from '@/stores/modules/api/menu';

export const useHomeStore = defineStore("home", {
    state() {
        return {
            data: {
                banner: {
                    list: reactive([
                        {
                            backgroundImgUrl: "",
                            mainTitle: "國家運動科學中心",
                            mainTitleEn: "TAIWAN INSTITUTE OF <br> SPORTS SCIENCE",
                            subTitle: "Taiwan Institute of Sports Science",
                            content: "推動運動科學研究及應用<br>提升國際競技水準與運動產業競爭力",
                            contentEn: "Committed to promoting national sports science research <br> Training outstanding athletes and nurturing sports science talents",
                            moreBtn: {
                                show: false,
                                text: "了解更多",
                                textEn: "Learn more",
                                color: "white",
                                icon: ['fas', 'chevron-right']
                            }
                        }
                    ])
                },
                news: {
                    category: {
                        active: ref(""),
                        list: reactive([{ title: "所有類別", alias: "" }])
                    },
                    dataList: {
                        headline: reactive({
                            type: "headline",
                            tag: "",
                            tagEn: "",
                            imgUrl: "",
                            date: "",
                            dep: "",
                            depEn: "",
                            title: "",
                            titleEn: "",
                            content: "",
                            newsPage: "info",
                            alias: "1",
                        }),
                        list: reactive([
                            {
                                tag: "",
                                tagEn: "",
                                imgUrl: "",
                                date: "",
                                tags: [{title:""}],
                                title: "",
                                titleEn: "",
                                content: "",
                                newsPage: "info",
                                alias: "1",
                            }
                        ])
                    },
                    originData: reactive({})
                },
                video: {
                    category: {
                        active: ref(""),
                        list: reactive([{ title: "所有類別", alias: "" }])
                    },
                    dataList: {
                        headline: reactive([
                            {
                                tag: "中心成果",
                                tagEn: "中心成果",
                                title: "臺灣運動科學新里程碑，最新訓練成果記錄影片",
                                titleEn: "臺灣運動科學新里程碑，最新訓練成果記錄影片",
                                content: "選手將能夠透過國家級的專業後勤團隊，獲得更多體能訓練、運動防護、物理治療、運動心理及運動營養等支援，讓他們的訓練可以更安全、更抓得準方向",
                                contentEn: "選手將能夠透過國家級的專業後勤團隊，獲得更多體能訓練、運動防護、物理治療、運動心理及運動營養等支援，讓他們的訓練可以更安全、更抓得準方向",
                                dep: "運動科學研究處",
                                depEn: "運動科學研究處",
                                publish_up: "2024/11/20",
                                videoUrl: "https://www.youtube.com/watch?v=gu33VzAU8qo",
                                alias: "1"
                            }
                        ]),
                        list: reactive([
                            {
                                video: "video",
                                category_title: "",
                                category_alias: "",
                                title: "",
                                titleEn: "",
                                content: "",
                                contentEn: "",
                                tags: [{title:""}],
                                publish_up: "",
                                videoUrl: "",
                                alias: "1"
                            }
                        ])
                    },
                    originData: reactive({})
                },
                links: {
                    dataList: {
                        list: reactive([
                            { url: "https://www.sa.gov.tw/", imgUrl: "", text: "教育部體育署" }
                        ])
                    },
                    originData: reactive({})
                }
            }
        }
    },
    getters: {
        getVideoHeadline: (state) => {
            return state.data.video.dataList.list.reduce((max, obj) => obj.featured_ordering > max.featured_ordering ? obj : max, state.data.video.dataList.list[0]);
        }
    },
    actions: {
        async initData() {
            await useApiMenuStore().fetchGetByAlias('home')
            const GetMenuResult = JSON.parse(JSON.stringify(useApiMenuStore().data.response))
            this.data.news.originData = GetMenuResult.data.items["home-latest-news"]
            this.data.video.originData = GetMenuResult.data.items["home-video"]
            this.data.links.originData = GetMenuResult.data.items["useful-links"]
            this.data.news.category.active = "all"
            this.initNewsData()
            this.initVideoData()
            this.initLinksData()
        },
        initNewsData() {
            // initNewsCategory
            const dataKeyArr = Object.keys(this.data.news.originData.items).filter((item) => {
                return item !== "中心公告"
            })
            if(dataKeyArr) {
                this.data.news.category.list = []
                for (const key of dataKeyArr) {
                    let categoryItem = { title: key === 'all'? "所有類別":key, alias: key }
                    this.data.news.category.list.push(categoryItem)
                }
            }

            this.initNewsList()
        },
        initNewsList() {
            this.data.news.dataList.list = this.data.news.originData.items[this.data.news.category.active]? JSON.parse(JSON.stringify(this.data.news.originData.items[this.data.news.category.active].mixed)):[]
            this.data.news.dataList.headline = this.data.news.originData.items[this.data.news.category.active]? JSON.parse(JSON.stringify(this.data.news.originData.items[this.data.news.category.active].featured[0])):[]
        },
        initVideoData() {
            this.initVideoList()
        },
        initVideoList() {
            this.data.video.dataList.list = this.data.video.originData.items? JSON.parse(JSON.stringify(this.data.video.originData.items.mixed)):[]
            this.data.video.dataList.headline = this.data.video.originData.items.featured? JSON.parse(JSON.stringify(this.data.video.originData.items.featured)):[]
        },
        initLinksData() {
            this.initLinksList()
        },
        initLinksList() {
            this.data.links.dataList.list = JSON.parse(JSON.stringify(this.data.links.originData.items.data))
        }
    }
})
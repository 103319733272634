<script setup>
import { defineProps } from 'vue'
import { ArrowRight } from '@element-plus/icons-vue'

const props = defineProps({
    BreadcrumbData: {
        type: Array,
        required: true,
        default() {
            return [
                { text: "首頁", textEn: "Home", to: "/" }
            ]
        } 
    }
})
</script>
<template>
    <div class="BaseBreadcrumb">
        <div class="container">
            <el-breadcrumb :separator-icon="ArrowRight">
                <el-breadcrumb-item v-for="item in props.BreadcrumbData" :key="item" :to="item.to? item.to:''">{{ $i18n.locale === "zh-TW" ? item.text : item.textEn }}</el-breadcrumb-item>
            </el-breadcrumb>
        </div>
    </div>
</template>
<style lang="scss">
.BaseBreadcrumb {
    position: absolute;
    top: 100px;
    width: 100%;
    z-index: 2;
    font-size: 0.875rem;
    @media screen and (max-width: $Tab) {
        position: static;
        width: 330px;
        margin-bottom: 35px;
        align-self: flex-end;
    }
    .el-breadcrumb {
        display: flex;
        justify-content: flex-end;
        flex-wrap: wrap;
        font-size: inherit;
        .el-breadcrumb__item {
            max-width: 315px;
            @media screen and (max-width: $Tab) {
                margin-top: 15px;
            }
            .el-breadcrumb__inner {
                width: 100%;
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
            }
            .el-icon.el-breadcrumb__separator {
                margin: 0 20px;
            }
            &:last-child {
                border-radius: 15px;
                background-color: $color-active-green;
                padding: 4px 20px;
                text-align: center;
            }
        }
    }
}
</style>
const ApiItem = [
    {
        name: "SearchItem",
        description: "搜尋",
        method: "POST",
        api: "/item/search"
    },
    {
        name: "GetItem",
        description: "取得單一文章",
        method: "GET",
        api: "/item/:*"
    }
]

export default ApiItem
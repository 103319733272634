<script setup>
import { defineProps } from 'vue'

const props = defineProps({
    color: {
        type: String,
        default: "blue"
    },
    text: {
        type: String,
        default: ""
    },
    icon: {
        type: Array,
        require: false
    },
    iconPosition: {
        type: String,
        default() {
            return 'right'
        }
    },
    to: {
        type: String,
        require: false
    }
})
</script>
<template>
    <RouterLink v-if="props.to" :to="props.to">
        <el-button class="BaseBtn w-100" :class="props.color" >
            <span v-if="props.icon && props.iconPosition === 'left'" class="left-icon border"><font-awesome-icon :icon="props.icon" /></span>
            {{ props.text }}
            <span v-if="props.icon && props.iconPosition === 'right'" class="right-icon"><font-awesome-icon :icon="props.icon" /></span>
        </el-button>
    </RouterLink>
    <el-button v-else class="BaseBtn w-100" :class="props.color" >
        <span v-if="props.icon && props.iconPosition === 'left'" class="left-icon"><font-awesome-icon :icon="props.icon" /></span>
        {{ props.text }}
        <span v-if="props.icon && props.iconPosition === 'right'" class="right-icon"><font-awesome-icon :icon="props.icon" /></span>
    </el-button>
</template>
<style lang="scss" scope>
.BaseBtn {
    position: relative;
    min-height: 56px;
    min-width: 175.5px;
    padding: 8px 48px 8px 15px;
    border-radius: 23px / 50% !important;
    font-size: 0.875rem;
    &.black {
        color: white;
        background-color: $color-primary-black;
        .right-icon {
            background-color: $color-btn-bg-icon;
            &.border {
                border-color: transparent !important;
            }
        }
        &:hover, &.hover {
            background-color: $color-primary;
        }
    }
    &.blue {
        color: white;
        background-color: $color-primary;
        .right-icon {
            border: 0.5px solid white;
        }
        &:hover, &.hover {
            background-color: white;
            color: $color-primary;
            .right-icon {
                border-color: $color-primary;
            }
        }
    }
    &.white {
        color: $color-primary;
        border: none;
        background-color: white;
        .right-icon {
            background-color: rgba(0, 77, 159, 0.14);
        }
        &:hover, &.hover {
            background-color: $color-primary;
            color: white;
            .right-icon {
                background-color: rgba(255, 255, 255, 0.3);
            }
        }
    }
    .right-icon {
        position: absolute;
        right: 3px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 46px;
        height: 46px;
        border-radius: 50%;
    }
    .left-icon {
        position: absolute;
        left: 3px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 46px;
        height: 46px;
        border-radius: 50%;
    }
}
</style>
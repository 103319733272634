<script setup>
import { watch, onBeforeMount, onMounted } from 'vue';
import { useI18n } from 'vue-i18n';
import { useHomeStore } from '@/stores/home';

import SectionBanner from "./SectionBanner.vue"
import SectionAbout from "./SectionAbout.vue"
import SectionNews from "./SectionNews.vue"
import SectionVideo from "./SectionVideo.vue"
import SectionLinks from "./SectionLinks.vue"

const { locale } = useI18n();
const homeStore = useHomeStore()
const homeData = homeStore.data

onBeforeMount(() => {
    homeStore.initData()
})
watch(locale, (nv, ov) => {
    if(ov !== localStorage.getItem("lang")) {
        homeStore.initData()
    }
})
onMounted(() => {
    fixBtnWithAriaLabel()
    setTimeout(() => {
        fixBtnWithAriaLabel()
    }, 1000)
    setTimeout(() => {
        fixBtnWithAriaLabel()
    }, 5500)
})
const fixBtnWithAriaLabel = () => {
    const BtnHtmlObjArr = document.querySelectorAll("button")
    if(!!BtnHtmlObjArr) {
        for(const BtnHtmlObj of BtnHtmlObjArr) {
            const AriaLabel = BtnHtmlObj.getAttribute("aria-label")
            if(!AriaLabel) {
                BtnHtmlObj.setAttribute("aria-label", "btn")
            }
        }
    }
}
const changerNewsCategory = (alias) => {
    homeData.news.category.active = alias
    homeStore.initNewsList()
}
</script>
<template>
    <div class="Home">
        <SectionBanner :BannerList="homeData.banner.list" />
        <SectionAbout />
        <SectionNews :NewsData="homeData.news.dataList" :NewsCategoryList="homeData.news.category.list" :ActiveNewsCategory="homeData.news.category.active" @changerCategory="changerNewsCategory" />
        <SectionVideo :VideoData="homeData.video.dataList" />
        <SectionLinks :LinksList="homeData.links.dataList.list" />
    </div>
</template>
  
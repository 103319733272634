export const formater = {
    dateFormate: (dateTimeStr = "", formatStr) => {
        let dateTime = dateTimeStr? new Date(dateTimeStr):""
        let result = dateTimeStr
        if(formatStr && dateTime) {
            let fixDateTime = new Date(dateTime.setHours(dateTime.getHours() + 8))
            result = ""
            const dateTimeObj = {
                Y: fixDateTime.getFullYear(),
                M: fixDateTime.getMonth() + 1,
                D: fixDateTime.getDate(),
                h: fixDateTime.getHours(),
                m: fixDateTime.getMinutes(),
                s: fixDateTime.getSeconds()
            }
            formatStr.split("").forEach((key) => {
                if(dateTimeObj[key]) {
                    result += dateTimeObj[key] < 10? "0" + dateTimeObj[key]:dateTimeObj[key]
                } else {
                    result += key
                }
            })
        }
        return result
    },
    videoIdFormate: (videoUrlStr) => {
        try {
            const UrlData = new URL(videoUrlStr)
            if(UrlData.host === "www.youtube.com" && UrlData.pathname === "/watch" ) {
                const params = new URLSearchParams(new URL(UrlData).search);
                return params.get("v")
            } else {
                return UrlData.pathname.replace("/","")
            }
        } catch(error) {
            console.error("Invalid URL:", error.message);
        }
    }
}
<template>
    <div class="ComingSoon">
        <img src="@/assets/images/commingSoon.png">
    </div>
</template>
<style lang="scss" scope>
.ComingSoon {
    display: flex;
    justify-content: center;
    align-items: center;
    img {
        max-width: 90vw;
        height: auto;
    }
}
</style>
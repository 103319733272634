import { reactive } from 'vue'
import { defineStore } from 'pinia'
import { useApiModuleStore } from '@/stores/modules/api/module';
import { useApiItemStore } from '@/stores/modules/api/item';

export const usePublicInfoStore = defineStore("publicInfo", {
    state() {
        return {
            data: {
                bannerData: reactive({
                    pageName: "public-info",
                    title: "Public Information",
                    subTitle: "公開資訊"
                }),
                breadcrumbData: reactive([
                    { text: "首頁", textEn: "Home", to: "/" },
                    { text: "公開資訊", textEn: "Public Information" },
                ]),
                categoryList: reactive({
                    titleKey: "NAV_PUBLICINFO",
                    menuData:{
                        mainUrl: "/public-info",
                        active: "",
                        list: []
                    }
                }),
                pagination: reactive({
                    current_page: 1,
                    first_page_url: "",
                    from: 1,
                    last_page: 2,
                    last_page_url: "",
                    next_page_url: "",
                    path: "",
                    per_page: 9,
                    prev_page_url: null,
                    to: 9,
                    total: 10
                }),
                subTitleData: reactive({
                    text: "",
                    textEn: ""
                }),
                list: reactive([]),
                pageData: {
                    'regulation': {
                        breadcrumbData: reactive([
                            { text: "法規", textEn: "Regulation" },
                        ]),
                        subTitleData: reactive({
                            text: "法規", textEn: "Regulation"
                        }),
                    },
                    'procedure': {
                        breadcrumbData: reactive([
                            { text: "辦法及要點", textEn: "Procedure" },
                        ]),
                        subTitleData: reactive({
                            text: "辦法及要點", textEn: "Procedure"
                        }),
                    },
                    'plan': {
                        breadcrumbData: reactive([
                            { text: "計畫", textEn: "Plan" },
                        ]),
                        subTitleData: reactive({
                            text: "計畫", textEn: "Plan"
                        }),
                    },
                    'budget': {
                        breadcrumbData: reactive([
                            { text: "預算及決算", textEn: "Budget" },
                        ]),
                        subTitleData: reactive({
                            text: "預算及決算", textEn: "Budget"
                        }),
                    },
                    'download': {
                        breadcrumbData: reactive([
                            { text: "下載專區", textEn: "Download" },
                        ]),
                        subTitleData: reactive({
                            text: "下載專區", textEn: "Download"
                        }),
                    },
                    'purchase': {
                        breadcrumbData: reactive([
                            { text: "採購作業實施規章", textEn: "Purchase" },
                        ]),
                        subTitleData: reactive({
                            text: "採購作業實施規章", textEn: "Purchase"
                        }), 
                    },
                    'other': {
                        breadcrumbData: reactive([
                            { text: "其他", textEn: "Other" },
                        ]),
                        subTitleData: reactive({
                            text: "其他", textEn: "Other"
                        }),
                    }
                }
            }
        }
    },
    getters: {
        getDownloadList: (originArr) => {
            let newArr = []
            originArr.forEach((item) => {
                if(!!item) {
                    let itemArr = item.split(".")
                    if(itemArr.length > 2) {
                        const tempArr = [ "", "" ]
                        itemArr.forEach((item, index) => {
                            if(index < itemArr.length - 1) {
                                tempArr[0] += item
                                if(index < itemArr.length - 2) {
                                    tempArr[0] += "."
                                }
                            } else {
                                tempArr[1] = item
                            }
                        })
                        itemArr = tempArr
                    }
                    let newArrItem = {
                        text: itemArr[0].split("/").length > 0? itemArr[0].split("/")[itemArr[0].split("/").length - 1]:itemArr[0],
                        type: itemArr[1],
                        href: item
                    }
                    newArr.push(newArrItem)
                }
            })
            return newArr
        }
    },
    actions: {
        async initData() {
            await useApiModuleStore().fetchGetByAlias('public-information')
            const GetModuleResult = JSON.parse(JSON.stringify(useApiModuleStore().data.response))
            await this.initCategory(GetModuleResult.data.items.items)
            await this.initList()
        },
        async initList() {
            this.data.breadcrumbData = [
                { text: "首頁", textEn: "Home", to: "/" },
                { text: "公開資訊", textEn: "Public Information" },
            ]
            this.data.pageData[this.data.categoryList.menuData.active].breadcrumbData.forEach((item) => {
                const copyItem = JSON.parse(JSON.stringify(item))
                this.data.breadcrumbData.push(copyItem)
            })
            this.data.subTitleData = this.data.pageData[this.data.categoryList.menuData.active].subTitleData
            const requestData = { 
                page: this.data.pagination.current_page,
                limit: 10,
                category_alias: [ this.data.categoryList.menuData.active ],
                language: localStorage.getItem("lang")? localStorage.getItem("lang"):"zh-Hant"
            }
            await useApiItemStore().fetchSearch(requestData)
            const result = JSON.parse(JSON.stringify(useApiItemStore().data.response))
            this.data.list = result.data.items
            this.data.pagination = result.data.pagination
            document.body.scrollTop = 0; // For Safari
            document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
        },
        async initCategory(ApiResultArr) {
            if(Array.isArray(ApiResultArr)) {
                this.data.categoryList.menuData.list = ApiResultArr
                const checkActive = this.data.categoryList.menuData.list.find((item) => {
                    return item.alias === this.data.categoryList.menuData.active
                })
                if(!checkActive) {
                    this.data.categoryList.menuData.active = this.data.categoryList.menuData.list[0].alias
                }
            }
        },
    }
})
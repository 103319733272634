import { ref } from 'vue'
import { defineStore } from 'pinia'
import request from '@/utils/request'

export const useApiItemStore = defineStore("api-item", {
    state() {
        return {
            data: {
                response: ref(null)
            }
        }
    },
    actions: {
        async fetchSearch(requestData = {
            language: "zh-Hant"
        }) {
            try {
                const responseData = await request.POST("SearchItem", requestData);
                
                // 将数据存储到响应式变量中
                this.data.response = responseData;
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        },
        async fetchGetByAlias(alias) {
            try {
                const requestData = { 
                    language: localStorage.getItem("lang")? localStorage.getItem("lang"):"zh-Hant"
                }
                const responseData = await request.GET("GetItem", requestData, [ alias ]);

                // 将数据存储到响应式变量中
                this.data.response = responseData;
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        }
    }
})
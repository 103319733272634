<script setup>
import { ref, defineProps, defineEmits } from 'vue'
import SectionNewsItem from "./SectionNewsItem.vue"
import BaseBtn from "@/components/Base/BaseBtn.vue"

const props = defineProps({
    ActiveNewsCategory: {
        type: String,
        default() {
            return "所有類別"
        }
    },
    NewsCategoryList: {
        type: Array,
        required: true,
        default() {
            return []
        }
    },
    NewsData: {
        type: Object,
        required: true,
        default() {
            return {
                headline: {},
                list: []
            }
        }
    }
})
const emit = defineEmits(['changerCategory']);
const NewsCarousel = ref(null)
const NewsCarouselHandler = (str) => {
    switch(str) {
        case "right":
            NewsCarousel.value.next()
        break
        case "left":
            NewsCarousel.value.prev()
        break
    }
}
const categoryHandler = (alias) => {
    emit('changerCategory', alias)
}
</script>
<template>
    <div id="news" class="SectionNews">
        <div class="container">
            <el-row :gutter="55">
                <el-col :span="12" class="d-none d-lg-block">
                    <div class="SectionHeader">
                        <div class="Title">
                            <div class="SectionTitle"><img v-if="$i18n.locale !== 'zh-TW'" class="icon" src="@/assets/images/icon/title_icon.svg" alt="">News</div>
                            <div class="SectionSubTitle" v-if="$i18n.locale === 'zh-TW'">
                                <img class="icon" src="@/assets/images/icon/title_icon.svg" alt="">最新消息
                            </div>
                        </div>
                    </div>
                    <router-link v-if="props.NewsData.headline" :to="{ name: 'announcement-page', params: { alias: props.NewsData.headline.alias } }"><SectionNewsItem :ItemData="props.NewsData.headline" class="headline" /></router-link>
                    <div class="mt-5" style="width: 225px;">
                        <BaseBtn :color="'black'" :text="$t('BTN_ALLARTICLE')" :icon="['fas', 'chevron-right']" :to="'/announcement'" />
                    </div>
                </el-col>
                <el-col :xs="24" :md="12">
                    <div class="SectionHeader">
                        <div class="Title">
                            <div class="d-block d-lg-none">
                                <div class="SectionTitle"><img v-if="$i18n.locale !== 'zh-TW'" class="icon" src="@/assets/images/icon/title_icon.svg" alt="">News</div>
                                <div class="SectionSubTitle" v-if="$i18n.locale === 'zh-TW'">
                                    <img class="icon" src="@/assets/images/icon/title_icon.svg" alt="">最新消息
                                </div>
                            </div>
                            <el-row :gutter="20" style="margin-top: 30px;">
                                <el-col v-for="(category, index) in props.NewsCategoryList" :key="index" :xs="12" :md="6">
                                    <el-button class="w-100 NewsCategoryBtn" :class="{ 'active': props.ActiveNewsCategory === category.alias }" round @click="categoryHandler(category.alias)">{{category.title}}</el-button>
                                </el-col>
                            </el-row>
                        </div>
                    </div>
                    <el-row :gutter="24" class="d-none d-lg-flex">
                        <el-col v-for="item in props.NewsData.list" :key="item" :md="12" :sm="20"  class="NewsItem">
                            <a v-if="item.link" :href="item.link" target="_blank"><SectionNewsItem :ItemData="item" /></a>
                            <router-link v-else :to="{ name: 'announcement-page', params: { alias: item.alias } }"><SectionNewsItem :ItemData="item" /></router-link>
                        </el-col>
                    </el-row>
                    <div class="d-block d-lg-none">
                        <el-carousel ref="NewsCarousel" :autoplay="false" indicator-position="none" arrow="never" height="325px" style="margin-bottom: 30px;">
                            <el-carousel-item v-for="item in props.NewsData.list" :key="item">
                                <a v-if="item.link" :href="item.link" target="_blank"><SectionNewsItem :ItemData="item" /></a>
                                <router-link v-else :to="{ name: 'announcement-page', params: { alias: item.alias } }"><SectionNewsItem :ItemData="item" /></router-link>
                            </el-carousel-item>
                        </el-carousel>
                        <el-row>
                            <el-col :span="12">
                                <BaseBtn :color="'black'" :text="$t('BTN_ALLARTICLE')" :icon="['fas', 'chevron-right']" :to="'/announcement'" class="w-100" />
                            </el-col>
                            <el-col :span="12">
                                <div class="NewsCarouselArrowBtns">
                                    <el-button @click="NewsCarouselHandler('left')">
                                        <font-awesome-icon :icon="['fas', 'chevron-left']" />
                                    </el-button>
                                    <el-button @click="NewsCarouselHandler('right')">
                                        <font-awesome-icon :icon="['fas', 'chevron-right']" />
                                    </el-button>
                                </div>
                            </el-col>
                        </el-row>
                    </div>
                </el-col>
            </el-row>
        </div>
    </div>
</template>

<script>
export default {
    methods: {
        newsInfo(item) {
            this.$emit('news-info-emit', item)
            console.log(item);
        }
    }
}
</script>

<style lang="scss" scope>
.SectionNews {
    padding: 120px 0;
    @media screen and (max-width: $Tab) {
        padding: 60px 0;
    }
    .SectionHeader {
        display: flex;
        justify-content: space-between;
        align-items: baseline;
        @media screen and (max-width: $Tab) {
            flex-wrap: wrap;
        }
        .Title {
            display: flex;
            align-items: baseline;
            margin-bottom: 50px;
            @media screen and (max-width: $Tab) {
                display: block;
                margin-bottom: 30px;
                width: 100%;
            }
            .SectionTitle {
                line-height: 0.7;
                font-size: 3.75rem;
                color: $color-primary;
                font-weight: 200;
                @media screen and (max-width: $Tab) {
                    margin-bottom: 12px;
                }
                .icon {
                    margin-right: 16px;
                }
            }
            .SectionSubTitle {
                font-size: 1rem;
                margin-left: 20px;
                @media screen and (max-width: $Tab) {
                    margin-left: 0;
                }
                .icon {
                    margin-right: 2px;
                    margin-bottom: 3px;
                }
            }
            .NewsCategoryBtn {
                height: 48px;
                padding: 0 40px;
                font-size: 1rem;
                border-color: $color-black-33;
                color: $color-black;
                border-radius: 30px;
                @media screen and (max-width: $Tab) {
                    margin-bottom: 15px;
                }
                &.active {
                    background-color: $color-primary-black;
                    color: white;
                }
                &:not(.active):hover{
                    background-color: white;
                    color: $color-primary;
                }
            }
        }
        .TitleTools {
            color: $color-primary;
            font-size: 1rem;
            @media screen and (max-width: $Tab) {
                width: 100%;
                margin-bottom: 20px;
            }
        }
    }
    .NewsItem {
        margin-bottom: 60px;
        @media screen and (max-width: $Tab) {
            margin-bottom: 20px;
        }
    }
    .NewsCarouselArrowBtns {
        display: flex;
        justify-content: end;
        align-items: center;
        .el-button {
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 50%;
            height: 48px;
            width: 48px;
            box-shadow: 0px 4px 10px 0px ;
        }
    }
}

</style>
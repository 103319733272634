import { reactive } from 'vue'
import { defineStore } from 'pinia'
import { useApiMenuStore } from '@/stores/modules/api/menu';

export const useAboutStore = defineStore("about", {
    state() {
        return {
            data: {
                bannerData: reactive({
                    pageName: "about",
                    title: "About TISS",
                    subTitle: "中心介紹"
                }),
                breadcrumbData: reactive([
                    { text: "首頁", textEn: "Home", to: "/" },
                    { text: "中心介紹", textEn: "About TISS" }
                ]),
                categoryList: reactive({
                    titleKey: "NAV_ABOUT",
                    menuData:{
                        mainUrl: "/about",
                        active: "",
                        list: [
                            { title: "使命、願景", titleKey:"NAV_OBJECTIVES", alias: "Objectives" },
                            { title: "中心任務", titleKey:"NAV_MISSION", alias: "Missions" },
                            { title: "組織概況", titleKey:"NAV_ORGANIZATION", alias: "Organization" },
                            { title: "第１屆 董監事", titleKey:"NAV_BOARDOFDIRECTORS", alias: "BOD" },
                            { title: "執行長", titleKey:"NAV_CEO", alias: "CEO" },
                            // { title: "董事長", alias: "Chairman" },
                            { title: "單位介紹", titleKey:"NAV_UNIT", alias: "Units" },
                        ]
                    }
                }),
                BODData: reactive([]),
                supervisorData: reactive([]),
                CEOData: reactive({
                    name: "黃啟煌",
                    nameEn: "Michael C.H. Huang",
                    secretaryName: "楊小姐",
                    secretaryNameEn: "Ms. Yang",
                    tel: "07-5861193",
                    mail: "00017@tiss.org.tw",
                    education: [
                        { text: "美國印地安那大學 體育系體育博士", textEn: "P.E.D., Indiana University" },
                        { text: "美國印地安那大學 體育系運動傷害防護碩士", textEn: "M.S. , Indiana University" },
                        { text: "美國春田學院 體育系運動行政管理碩士", textEn: "M.A., Springfield College" },
                        { text: "輔仁大學 體育系文學士", textEn: "B.A., Fu Jen Catholic University" }
                    ],
                    experience: [
                        { text: "國立體育大學 副校長", textEn: "Vice President, National Taiwan Sport University" },
                        { text: "行政院體育委員會 副主任委員", textEn: "Deputy Chairperson, Sport Affairs Council" },
                        { text: "台灣運動傷害防護學會 理事長", textEn: "Executive Yuan President, Taiwan Athletic Trainers’ Society Sports Director" },
                        { text: "2009高雄世界運動會 運動總監", textEn: "The World Games 2009 Director" },
                        { text: "國家運動選手訓練中心 主任", textEn: "National Sports Training Center Executive Director, Sports Medicine Association R.O.C" }
                    ]
                }),
                ChairmanData: reactive({
                    name: "",
                    nameEn: "",
                    secretaryName: "",
                    secretaryNameEn: "",
                    tel: "",
                    mail: "",
                    education: [],
                    experience: []
                })
            }
        }
    },
    actions: {
        async initData() {
            await this.initCEOData()
            await this.initBODData()
        },
        async initBODData() {
            // 從後台取得董監事的資料並初始化
            await useApiMenuStore().fetchGetByAlias("directors-and-supervisors")
            const result = JSON.parse(JSON.stringify(useApiMenuStore().data.response))
            this.data.BODData = result.data.items["board-of-directors"].items.data
            this.data.supervisorData = result.data.items["supervisors"].items.data
        },
        async initCEOData() {
            await useApiMenuStore().fetchGetByAlias("ceo")
            const result = JSON.parse(JSON.stringify(useApiMenuStore().data.response))
            this.data.CEOData = result.data.items.ceo.items.data[0]
        }
    }
})
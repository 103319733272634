import { reactive, ref } from 'vue'
import { defineStore } from 'pinia'
import { useApiModuleStore } from '@/stores/modules/api/module';

export const useGlobalStore = defineStore("global", {
    state() {
        return {
            data: {
                lang: ref("zh-Hant"),
                fontSize: ref(16),
                currentHash: ref(""),
                isShowMenu: ref(false),
                linkList: reactive([
                    {
                        textKey: "NAV_HOME",
                        name: "home", //不可與其他選項相同
                        to: "/",
                        childrenList: [
                            {
                                textKey: "NAV_ABOUTUS",
                                to: "/#about",
                            },
                            {
                                textKey: "NAV_NEWS",
                                to: "/#news",
                            },
                            {
                                textKey: "NAV_VIDEO",
                                to: "/#video",
                            },
                            {
                                textKey: "NAV_LINKS",
                                to: "/#links",
                            }
                        ]
                    },
                    {
                        textKey: "NAV_NEWS",
                        name: "news",
                        to: "/announcement",
                        childrenList: [
                            {
                                textKey: "NAV_ANNOUNCEMENT",
                                to: "/announcement",
                            },
                            {
                                textKey: "NAV_VIDEO",
                                to: "/video",
                            }
                        ]
                    },
                    {
                        textKey: "NAV_ABOUT",
                        name: "about",
                        to: "/about",
                        childrenList: [
                            {
                                textKey: "NAV_OBJECTIVES",
                                to: "/about#Objectives",
                            },
                            {
                                textKey: "NAV_MISSION",
                                to: "/about#mission",
                            },
                            {
                                textKey: "NAV_ORGANIZATION",
                                to: "/about#Organization",
                            },
                            {
                                textKey: "NAV_BOARDOFDIRECTORS",
                                to: "/about#BOD",
                            },
                            {
                                textKey: "NAV_CEO",
                                to: "/about#CEO",
                            },
                            {
                                textKey: "NAV_UNIT",
                                to: "/about#Units",
                            },
                            // {
                            //     textKey: "NAV_Chairman",
                            //     to: "/about#Chairman",
                            // }
                        ]
                    },
                    // {
                    //     textKey: "NAV_UNIT",
                    //     name: "unit",
                    //     to: "/unit",
                    //     childrenList: [
                    //         {
                    //             textKey: "NAV_SMRD",
                    //             to: "/unit#SMRD",
                    //         },
                    //         {
                    //             textKey: "NAV_SSRD",
                    //             to: "/unit#SSRD",
                    //         },
                    //         {
                    //             textKey: "NAV_STDD",
                    //             to: "/unit#STDD",
                    //         },
                    //         {
                    //             textKey: "NAV_ADMIN",
                    //             to: "/unit#Admin",
                    //         }
                    //     ]
                    // },
                    {
                        textKey: "NAV_RESEARCH",
                        name: "research",
                        to: "/research",
                        childrenList: [
                            {
                                textKey: "NAV_SSR",
                                to: "/research#sport-science-research",
                            },
                            {
                                textKey: "NAV_STD",
                                to: "/research#sport-tech-and-information",
                            },
                            {
                                textKey: "NAV_SMR",
                                to: "/research#sport-medicine-research",
                            }
                        ]
                    },
                    {
                        textKey: "NAV_PUBLICINFO",
                        name: "public-info",
                        to: "/public-info#regulation",
                        childrenList: [
                            {
                                textKey: "NAV_REGULATION",
                                to: "/public-info#Regulation",
                            },
                            {
                                textKey: "NAV_METHODS",
                                to: "/public-info#Methods",
                            },
                            {
                                textKey: "NAV_PLAN",
                                to: "/public-info#Plan",
                            },
                            {
                                textKey: "NAV_BUDGET",
                                to: "/public-info#Budget",
                            },
                            {
                                textKey: "NAV_DOWNLOAD",
                                to: "/public-info#Download",
                            },
                            {
                                textKey: "NAV_PURCHASE",
                                to: "/public-info#Purchase",
                            },
                            {
                                textKey: "NAV_OTHER",
                                to: "/public-info#Other",
                            }
                        ]
                    },
                    {
                        textKey: "NAV_MAIL",
                        name: "email",
                        href: "mailto:service@tiss.org.tw",
                        to: ""
                    }
                ]),
                hoverNav: {
                    show: ref(false),
                    list: reactive([])
                }
            }
        }
    },
    getters: {
        menuNavList: (state) => {
            return state.data.linkList.filter((item) => {
                return item.name !== "home"
            })
        },
        footerList: (state) => {
            return state.data.linkList.filter((item) => {
                return item.name !== "home" && item.name !== "email"
            })
        }
    },
    actions: {
        async initPublicInfoMenuData() {
            await useApiModuleStore().fetchGetByAlias('public-information')
            const GetModuleResult = JSON.parse(JSON.stringify(useApiModuleStore().data.response))
            await this.initCategory(GetModuleResult.data.items.items)
        },
        async initCategory(ApiResultArr) {
            const publicInfoMenuList = this.data.linkList.find((item) => {
                return item.name === "public-info"
            })
            if(publicInfoMenuList && Array.isArray(ApiResultArr)) {
                publicInfoMenuList.childrenList = []
                for(const item of ApiResultArr) {
                    let children = {
                        text: item.title,
                        textKey: "NAV_"+item.alias.toUpperCase(),
                        to: "/public-info#"+item.alias
                    }
                    publicInfoMenuList.childrenList.push(children)
                }
            }
        },
        toggleMenu() {
            this.data.isShowMenu = !this.data.isShowMenu
        },
        setFontSize(value) {
            if(value) {
                this.data.fontSize = value
            }
        },
        setHoverNavList(name) {
            if(name) {
                const targetList = this.data.linkList.find((item) => {
                    return item.name === name
                })
                if(targetList) {
                    this.data.hoverNav.show = !!targetList.childrenList
                    this.data.hoverNav.list = targetList.childrenList? targetList.childrenList:[]
                }
            } else {
                this.data.hoverNav.show = false
            }
        },
    }
})
<script setup>
import { defineProps } from 'vue'

const props = defineProps({
    list: {
        type: Array,
        default() {
            return []
        }
    },
    listType: {
        type: String,
        default() {
            return "download"
        }
    }
})
</script>
<template>
    <div v-if="props.listType === 'download'" class="BaseTagList" :class="props.listType">
        <span v-for="(tag, index) in props.list" :key="index">
            <a v-if="tag.type === 'pdf'" :href="tag.href" :target="'_blank'" >
                <el-tag :class="tag.type" round>{{ tag.type }}</el-tag>
            </a>
            <a v-else :href="tag.href" :download="tag.text+'.'+tag.type" >
                <el-tag :class="tag.type" round>{{ tag.type }}</el-tag>
            </a>
        </span>
    </div>
    <div v-else class="BaseTagList">
        <span v-for="(tag, index) in props.list" :key="index">
            <el-tag :class="tag.type" round>{{ tag.text }}</el-tag>
        </span>
    </div>
</template>
<style lang="scss" scope>
.BaseTagList {
    display: flex;
    .el-tag {
        margin-right: 14px;
        border: none;
        font-size: 0.875rem;
        @media screen and (max-width: $Tab) {
            padding: 9px 15px;
        }
    }
    &.download {
        .pdf {
            color: #E05650;
            background-color: #FFE9E8;
        }
        .doc {
            color: #003D9F;
            background-color: #E2EDFF;
        }
        .odf {
            color: #21ACED;
            background-color: #D9F3FF;
        }
    }
}
</style>
<script setup>
import { defineProps } from 'vue'
import BaseBtn from "@/components/Base/BaseBtn.vue"

const props = defineProps({
    ItemData: {
        type: Object,
        required: true,
        default() {
            return {
                backgroundImgUrl: "",
                mainTitle: "國家運動科學中心",
                mainTitleEn: "TAIWAN INSTITUTE OF <br> SPORTS SCIENCE",
                subTitle: "Taiwan Institute of Sports Science",
                content: "致力於推動全國運動科學研究<br>培訓優秀說及培育運動科學人才",
                contentEn: "Committed to promoting national sports science research <br> Training outstanding athletes and nurturing sports science talents",
                moreBtn: {
                    show: true,
                    to: "/about",
                    text: "了解更多",
                    textEn: "Learn more",
                    color: "white",
                    icon: ['fas', 'chevron-right']
                }
            }
        } 
    }
})
</script>
<template>
    <div class="SectionBannerItem">
        <img v-if="props.ItemData.backgroundImgUrl" class="SectionBannerItemBg" :src="props.ItemData.backgroundImgUrl" alt="">
        <img v-if="!props.ItemData.backgroundImgUrl" class="SectionBannerItemBg" src="@/assets/images/home/banner_home.jpg" alt="">
        <div class="SectionBannerItemContent">
            <div v-if="$i18n.locale === 'zh-TW'">{{ props.ItemData.subTitle }}</div>
            <h1 class="main-title" v-html="$i18n.locale === 'zh-TW'? props.ItemData.mainTitle : props.ItemData.mainTitleEn"></h1>
            <p class="content" v-html="$i18n.locale === 'zh-TW'? props.ItemData.content : props.ItemData.contentEn"></p>
            <BaseBtn v-if="props.ItemData.moreBtn.show" :color="props.ItemData.moreBtn.color" :text="$i18n.locale === 'zh-TW'? props.ItemData.moreBtn.text : props.ItemData.moreBtn.textEn" :icon="props.ItemData.moreBtn.icon" :to="props.ItemData.moreBtn.to? props.ItemData.moreBtn.to:'/about'" />
        </div>
    </div>
</template>
<style lang="scss" scoped>
.SectionBannerItem {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    clip-path: ellipse(100% 55% at 50% 45%);
    .SectionBannerItemBg {
        width: 100%;
        height: 100%;
        @media screen and (max-width: $Tab) {
            width: auto;
        }
    }

    .SectionBannerItemContent {
        position: absolute;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        transform: translate(-50%, -50%);
        color: white;
        text-align: center;
        top: 50%;
        left: 50%;
        width: 100%;
        max-width: 800px;
        height: 100%;
        backdrop-filter: blur(24.5px);
        font-size: 1rem;
        letter-spacing: 0.5rem;
        @media screen and (max-width: $Tab) {
            height: 250px;
        }
        .main-title {
            font-size: 3.5rem;
            margin: 8px 0 18px 0;
        }
        .content {
            font-size: 1.5rem;
            margin-bottom: 10px;
            line-height: 2;
        }
        .SectionBannerBtn {
            position: relative;
            color: $color-primary;
            min-width: 228px;
            min-height: 56px;
            border-radius: 23px / 50%;
            .right-icon {
                position: absolute;
                right: 2px;
                display: flex;
                align-items: center;
                justify-content: center;
                width: 46px;
                height: 46px;
                border: 0.5px solid #004D9F66;
                border-radius: 50%;
            }
        }
    }
}
</style>
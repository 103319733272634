<script setup>
import { ref, onBeforeMount, onMounted, getCurrentInstance } from 'vue';
import { useResearchStore } from '@/stores/research';

import BasePageLayout from '@/components/Base/BasePageLayout/Index.vue';

const { proxy } = getCurrentInstance();

const researchStore = useResearchStore()
const researchData = researchStore.data

const BannerData = {
    pageName: "research-page",
}
const BreadcrumbData = ref(researchData.breadcrumbData)

onBeforeMount(async () => {
    updateNewsDetail()
})
onMounted(() => {
    setTimeout(() => {
        proxy.$facebook.init().then(proxy.$facebook.fixMsgBoardCss())
    }, 8000)
})
const updateNewsDetail = () => {
    const currentData = researchData.pageData
    if(!currentData) {
        window.location = '/research'
    } else {
        BreadcrumbData.value = JSON.parse(JSON.stringify(researchData.breadcrumbData))
        const BreadcrumbItem = {
            text: currentData.title, textEn: currentData.titleEn
        }
        BreadcrumbData.value.push(BreadcrumbItem)
    }
}
const share = (socialName) => {
    const urlForLine = 'https://social-plugins.line.me/lineit/share?url=' + encodeURIComponent(window.location.href);
    const urlForX = 'https://twitter.com/intent/tweet?' + 'text=&' + 'url=' + window.location.href;
    switch(socialName) {
        case "FB":
            if (window.FB) {
                window.FB.getLoginStatus(function(response) {
                    if(response.status === "connected") {
                        window.FB.ui({
                            method: 'share',
                            display: "popup",
                            href: window.location.href, // 要分享的页面的 URL
                        });
                    } else {
                        window.FB.login(function(){
                            share(socialName)
                        });
                    }
                });
            }
        break
        case "Line":
            window.open(urlForLine, '_blank');
        break
        case "X":
            window.open(urlForX, '_blank');
        break
        default: 
            console.error("undefined socialName")
        break
    }
}
</script>
<template>
    <BasePageLayout :BannerData="BannerData" :BreadcrumbData="BreadcrumbData" :AsideData="researchData.categoryList">
        <div class="MainContainer">
            <div class="ResearchPage">
                <div class="HeadInfo">
                    <div class="HeadInfoItem">{{ $formater.dateFormate(researchData.pageData.publish_up, "Y/M/D") }}</div>
                    <div class="HeadInfoItem"><el-tag>{{ researchData.pageData.category_title }}</el-tag></div>
                </div>
                <div class="NewsTitle">{{ researchData.pageData.title }}</div>
                <div class="NewsToolBar">
                    <div class="NewsTagList">
                        <span>{{ $t("ANNOUNCEMENT_ARTICLE_TAG") }}：</span>
                        <el-tag v-if="researchData.pageData.tags.length > 0" effect="plain" round>{{ researchData.pageData.tags[0].title }}</el-tag>
                    </div>
                    <div class="NewsParams">
                        <div class="NewsParamsItem">
                            <span><font-awesome-icon :icon="['far', 'eye']" /></span>
                            <span>{{ researchData.pageData.hits }}</span>
                        </div>
                    </div>
                </div>
                <div class="NewsContent">
                    <div v-if="researchData.pageData.description" class="ContentBox">
                        <!-- <router-link :to="{ name: 'research-page', params: { alias: parseInt(researchData.pageData.alias) > 1? parseInt(researchData.pageData.alias)-1:researchData.pageData.alias } }">
                            <el-button class="ContentArrow left" circle><font-awesome-icon :icon="['fas', 'chevron-left']" /></el-button>
                        </router-link>
                        <router-link :to="{ name: 'research-page', params: { alias: parseInt(researchData.pageData.alias) < 2? parseInt(researchData.pageData.alias)+1:researchData.pageData.alias } }">
                            <el-button class="ContentArrow right" circle><font-awesome-icon :icon="['fas', 'chevron-right']" /></el-button>
                        </router-link> -->
                        <div v-html="researchData.pageData.description"></div>
                    </div>
                    <div class="ShareToolBar">
                        <div class="ShareToolBarTitle">Share On</div>
                        <ul class="SocialList">
                            <li>
                                <el-button aria-label="share-fb" circle @click="share('FB')"><font-awesome-icon :icon="['fab', 'facebook-f']" /></el-button>
                            </li>
                            <li>
                                <el-button aria-label="share-line" circle @click="share('Line')"><img src="@/assets/images/icon/line_icon_black.svg" alt="line"></el-button>
                            </li>
                            <li>
                                <el-button aria-label="share-x" circle @click="share('X')"><font-awesome-icon :icon="['fab', 'x-twitter']" /></el-button>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="NewsToolBar d-none d-lg-block">
                    <div class="NewsTagList">
                        <el-tag effect="plain" round>{{ researchData.pageData.tags? researchData.pageData.tags[0].title:"" }}</el-tag>
                    </div>
                </div>
                <div class="NewsControlBar">
                    <router-link :to="{ name: 'research-page', params: { alias: parseInt(researchData.pageData.alias) > 1? parseInt(researchData.pageData.alias)-1:researchData.pageData.alias } }">
                        <el-button text>
                            <font-awesome-icon :icon="['fas', 'chevron-left']" />
                            <span class="ms-2">{{ $t('VIDEO_PREVIOUSARTICL') }}</span>
                        </el-button>
                    </router-link>
                    <router-link :to="{ name: 'research-page', params: { alias: parseInt(researchData.pageData.alias) < 3? parseInt(researchData.pageData.alias)+1:researchData.pageData.alias } }">
                        <el-button text>
                            <span class="me-2">{{ $t('VIDEO_NEXTARTICL') }}</span>
                            <font-awesome-icon :icon="['fas', 'chevron-right']" />
                        </el-button>
                    </router-link>
                </div>
                <!-- <div class="CommentBoard">
                    <div class="fb-comments" data-href="https://tiss.org.tw/" data-lazy="true" data-width="" data-numposts="5"></div>
                </div> -->
            </div>
        </div>
    </BasePageLayout>
</template>
<style lang="scss" scope>
.ResearchPage {
    margin-top: 175px;
    margin-left: -32px;
    padding: 86px 170px;
    border-radius: 10px;
    background-color: white;
    @media screen and (max-width: $Tab) {
        padding: 50px 20px;
        margin-top: 0;
        margin-left: 0; 
    }
    .HeadInfo {
        display: flex;
        margin-bottom: 30px;
        margin-left: -24px;
        margin-right: -24px;
        .HeadInfoItem {
            display: flex;
            font-size: 1rem;
            align-items: center;
            padding: 0 24px;
            &:not(:last-child) {
                border-right: 1px solid $color-light-gray;
            }
            .el-tag {
                font-size: 1rem;
                color: white;
                height: auto;
                background-color: $color-primary-black;
                padding: 7px 25px;
                border-radius: 10px;
            }
        }
    }
    .NewsTitle {
        font-size: 3rem;
        letter-spacing: 7.2px;
        text-align: justify;
        color: $color-deep-black;
        @media screen and (max-width: $Tab) {
            font-size: 1.5rem;
        }
    }
    .NewsToolBar {
        display: flex;
        justify-content: space-between;
        border-top: 1px solid $color-dash-border;
        padding-top: 24px;
        margin: 50px 0;
        @media screen and (max-width: $MD) {
            margin: 20px 0 40px 0;
        }
        &.Top {
            border-bottom: 1px solid $color-dash-border;
        }
        .NewsTagList {
            .el-tag {
                padding: 6px 16px;
                height: auto;
                border-color: $color-primary;
                color: $color-primary;
            }
        }
        .NewsParams {
            display: flex;
            .NewsParamsItem {
                &:not(:last-child) {
                    margin-right: 40px;
                    @media screen and (max-width: $MD) {
                        margin-bottom: 12px;
                    }
                }
                span:not(:last-child) {
                    margin-right: 8px;
                }
            }
        }
    }
    .NewsContent {
        position: relative;
        display: flex;
        padding-right: 205px;
        min-height: 300px;
        @media screen and (max-width: $Tab) {
            display: block;
            padding-right: 0;
        }
        .ContentBox {
            position: relative;
            * {
                max-width: 100%;
            }
            p {
                font-size: 1.125rem;
                @media screen and (max-width: $Tab) {
                    font-size: 0.875rem;
                }
            }
            img {
                border-radius: 10px;
                height: auto;
            }
            ol {
                list-style-type: decimal;
            }
            ul {
                list-style-type: initial;
            }
            .ContentArrow {
                position: absolute;
                width: 56px;
                height: 56px;
                color: $color-primary;
                margin: 0;
                top: 50%;
                &:hover {
                    background-color: $color-primary;
                    color: white;
                    border-color: $color-primary;
                }
                &.left {
                    transform: translate(-100%, 50%);
                    left: -38px;
                }
                &.right {
                    transform: translate(100%, 50%);
                    right: -38px;
                }
            }
        }
        .ShareToolBar {
            position: absolute;
            right: 0;
            top: 0;
            width: 65px;
            border-left: 1px solid $color-light-gray;
            @media screen and (max-width: $Tab) {
                display: flex;
                justify-content: space-between;
                align-items: center;
                position: static;
                width: 100%;
                border-left: 0;
                border-top: 1px solid $color-light-gray;
                margin-top: 40px;
            }
            .ShareToolBarTitle {
                font-family: Switzer;
                font-size: 1.125rem;
                transform: translate(0%, calc(50% + 6px)) rotate(90deg);
                text-wrap: nowrap;
                @media screen and (max-width: $Tab) {
                    padding-top: 20px;
                    transform: none;
                }
            }
            .SocialList {
                padding-top: 85px;
                padding-left: 25px;
                margin: 0;
                @media screen and (max-width: $Tab) {
                    padding-top: 20px;
                    display: flex;
                }
                li {
                    &:not(:last-child){
                        margin-bottom: 10px;
                    }
                    .el-button {
                        height: 40px;
                        width: 40px;
                        color: $color-primary-black;
                        background-color: $color-icon-bg-gray;
                        border: 0;
                    }
                }
            }
        }
    }
    .NewsControlBar {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-bottom: 100px;
        font-size: 1rem;
        @media screen and (max-width: $Tab) {
            padding-top: 50px;
            padding-bottom: 50px;
        }
        .el-button {
            font-size: inherit;
        }
    }
    .CommentBoard {
        display: flex;
        align-items: center;
        justify-content: center;
    }
}
</style>
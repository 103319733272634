<script setup>
import { watch } from 'vue';
import { useRoute } from 'vue-router';
import { useGlobalStore } from '@/stores/global';
import Layout from "./components/Layout/Index.vue";

const route = useRoute()
const globalStore = useGlobalStore()
const globalData = globalStore.data

watch(route, (newVal) => {
    if(newVal) {
        globalData.currentRouteName = newVal.name
        globalData.currentHash = newVal.hash? newVal.hash.replace("#", ""):""
    }
})
watch(() => globalData.fontSize, (newVal) => {
    if(newVal) {
        document.documentElement.style.fontSize = newVal + "px"
    }
})

// 解决ERROR ResizeObserver loop completed with undelivered notifications.
const debounce = (fn, delay) => {
	let timer = null;
	return function () {
		let context = this;
		let args = arguments;
		clearTimeout(timer);
		timer = setTimeout(function () {
			fn.apply(context, args);
		}, delay);
	};
};
const _ResizeObserver = window.ResizeObserver;
window.ResizeObserver = class ResizeObserver extends _ResizeObserver {
	constructor(callback) {
		callback = debounce(callback, 16);
		super(callback);
	}
};
</script>
<template>
	<!-- 動態變更網頁Tab Title -->
	<!-- <metainfo>
		<template v-slot:title="{ content }">{{ content ? `${content} | 國家運動科學中心` : `國家運動科學中心` }}</template>
	</metainfo> -->
	<Layout>
		<router-view/>
	</Layout>
</template>
<style lang="scss">
body {
	color: $color-black;
	a {
		text-decoration: none;
	}
	ol, ul {
		list-style-type: none;
	}
	img {
		object-fit: cover;
		object-position: 50% 50%;
	}
}
</style>

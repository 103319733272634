<script setup>
import { defineProps } from 'vue'

const props = defineProps({
    BannerData: {
        type: Object,
        required: true,
        default() {
            return {
                pageName: "",
                backgroundImgUrl: "",
                title: "About TISS",
                subTitle: "中心簡介"
            }
        } 
    }
})
</script>
<template>
    <div class="BasePageBanner">
        <div class="Mask"></div>
        <img v-if="props.BannerData.backgroundImgUrl" class="PageBannerItemBg" :src="props.BannerData.backgroundImgUrl" alt="">

        <img v-else-if="props.BannerData.pageName === 'about'" class="PageBannerItemBg" src="@/assets/images/about/about_banner_1.jpg" alt="">
        <img v-else-if="props.BannerData.pageName === 'announcement' || props.BannerData.pageName === 'announcement-page'" class="PageBannerItemBg d-none d-lg-block" src="@/assets/images/news/news_banner.jpg" alt="">
        <img v-else-if="props.BannerData.pageName === 'video' || props.BannerData.pageName === 'video-page'" class="PageBannerItemBg d-none d-lg-block" src="@/assets/images/video/video_banner.jpg" alt="">
        <img v-else-if="props.BannerData.pageName === 'unit'" class="PageBannerItemBg d-none d-lg-block" src="@/assets/images/unit/banner_units.jpg" alt="">
        <img v-else-if="props.BannerData.pageName === 'research' || props.BannerData.pageName === 'research-page'" class="PageBannerItemBg d-none d-lg-block" src="@/assets/images/research/banner_research.jpg" alt="">
        <img v-else-if="props.BannerData.pageName === 'public-info'" class="PageBannerItemBg d-none d-lg-block" src="@/assets/images/public/banner_public.jpg" alt="">
        <img v-else-if="props.BannerData.pageName === 'sitemap'" class="PageBannerItemBg d-none d-lg-block" src="@/assets/images/sitemap/banner_sitemap.jpg" alt="">

        <img v-if="props.BannerData.pageName === 'announcement' || props.BannerData.pageName === 'announcement-page'" class="PageBannerItemBg d-block d-lg-none" src="@/assets/images/news/banner_announcement.jpg" alt="">
        <img v-else-if="props.BannerData.pageName === 'video' || props.BannerData.pageName === 'video-page'" class="PageBannerItemBg d-block d-lg-none" src="@/assets/images/video/banner_video.jpg" alt="">
        <img v-else-if="props.BannerData.pageName === 'unit'" class="PageBannerItemBg d-block d-lg-none" src="@/assets/images/unit/banner_units_m.jpg" alt="">
        <img v-else-if="props.BannerData.pageName === 'research' || props.BannerData.pageName === 'research-page'" class="PageBannerItemBg d-block d-lg-none" src="@/assets/images/research/banner_research_m.jpg" alt="">
        <img v-else-if="props.BannerData.pageName === 'public-info'" class="PageBannerItemBg d-block d-lg-none" src="@/assets/images/public/banner_public_m.jpg" alt="">
        <img v-else-if="props.BannerData.pageName === 'sitemap'" class="PageBannerItemBg d-block d-lg-none" src="@/assets/images/sitemap/banner_sitemap_m.jpg" alt="">
        
        <div class="container">
            <div v-if="props.BannerData.title" class="Title">
                <div class="BannerTitle"><img v-if="$i18n.locale !== 'zh-TW'" class="icon" src="@/assets/images/icon/title_icon_white.svg" alt="">{{ props.BannerData.title }}</div>
                <div class="BannerSubTitle" v-if="$i18n.locale === 'zh-TW'">
                    <img class="icon" src="@/assets/images/icon/title_icon_white.svg" alt="">{{ props.BannerData.subTitle }}
                </div>
            </div>
        </div>
    </div>
</template>
<style lang="scss">
.BasePageBanner {
    position: absolute;
    display: flex;
    align-items: center;
    width: 100%;
    height: 400px;
    clip-path: ellipse(100% 55% at 50% 45%);
    z-index: 2;
    @media screen and (max-width: $Tab) {
        height: 250px;
    }
    .Mask {
        position: absolute;
        background-color: $color-banner-mask;
        width: 100%;
        height: 100%;
        z-index: -1;
    }
    .PageBannerItemBg {
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: -2;
    }
    .Title {
        margin-bottom: 50px;
        color: white;
        @media screen and (max-width: $Tab) {
            margin-bottom: 0px;
            line-height: 1;
        }
        .BannerTitle {
            font-size: 3.75rem;
            font-weight: 200;
            @media screen and (max-width: $Tab) {
                font-size: 3rem;
                margin-bottom: 12px;
            }
            .icon {
                margin-right: 16px;
            }
        }
        .BannerSubTitle {
            font-size: 1rem;
            max-height: 16px;
            margin-top: 4px;
            @media screen and (max-width: $Tab) {
                font-size: 0.875rem;
            }
            .icon {
                margin-right: 2px;
                margin-bottom: 3px;
            }
        }
    }
}
</style>
const ApiCategory = [
    {
        name: "SearchCategory",
        description: "搜尋",
        method: "POST",
        api: "/category/search"
    },
    {
        name: "GetCategory",
        description: "取得單一分類",
        method: "GET",
        api: "/category/:*"
    }
]

export default ApiCategory
import { reactive } from 'vue'
import { defineStore } from 'pinia'

export const useSitemapStore = defineStore("sitemap", {
    state() {
        return {
            data: {
                bannerData: reactive({
                    pageName: "sitemap",
                    title: "Sitemap",
                    subTitle: "網站導覽"
                }),
                breadcrumbData: reactive([
                    { text: "首頁", textEn: "Home", to: "/" },
                    { text: "網站導覽", textEn: "Sitemap" },
                ])
            }
        }
    }
})


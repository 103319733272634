<script setup>
import { watch, defineProps, onBeforeMount } from 'vue';
// import { useI18n } from 'vue-i18n';
import { useAnnouncementStore } from '@/stores/announcement';

import BasePageLayout from '@/components/Base/BasePageLayout/Index.vue';
import BasePagination from '@/components/Base/BasePagination.vue';
import SectionNewsItem from '../Home/SectionNewsItem.vue';

// const { locale } = useI18n();
const announcementStore = useAnnouncementStore()
const announcementData = announcementStore.data
const props = defineProps({
    hash: {
        type: String
    }
})
watch(props, () => {
    announcementData.categoryList.menuData.active = props.hash.replace("#", "")
    announcementStore.initIndexList()
})
// watch(locale, (nv, ov) => {
//     if(ov !== localStorage.getItem("lang")) {
//         announcementStore.initIndexData()
//     }
// })
onBeforeMount(() => {
    if(!localStorage.getItem("page")){
        localStorage.setItem("page", 1)
    }
    announcementData.categoryList.menuData.active = props.hash.replace("#", "")
    announcementStore.initIndexData()
})
const currentPageChange = (page) => {
    localStorage.setItem("page", page)
    announcementData.pagination.current_page = page
    announcementStore.initIndexList()
}
</script>
<template>
    <BasePageLayout :BannerData="announcementData.bannerData" :BreadcrumbData="announcementData.breadcrumbData" :AsideData="announcementData.categoryList" bgColor="white">
        <div class="MainContainer">
            <div class="AnnouncementList">
                <div class="container"> 
                    <el-row :gutter="24">
                        <el-col v-for="item in announcementData.list" :key="item" :md="8" :sm="24"  class="NewsItem">
                            <a v-if="item.link" :href="item.link" target="_blank"><SectionNewsItem :ItemData="item" /></a>
                            <router-link v-else :to="{ name: 'announcement-page', params: { alias: item.alias } }"><SectionNewsItem :ItemData="item" /></router-link>
                        </el-col>
                    </el-row>
                </div>
            </div>
            <div class="Pagination">
                <div class="container">
                    <div class="content">
                        <BasePagination position="center" :layout="['go-first', 'prev', 'pager', 'next', 'go-last']"
                            :pagination="announcementData.pagination"
                            @current-change="currentPageChange"
                        />
                    </div>
                </div>
            </div>
        </div>
    </BasePageLayout>
</template>
<style lang="scss" scope>
.NewsCategoryTabs {
    padding: 130px 0 0 0;
    .CategoryTab {
        width: 100%;
        min-height: 48px;
        border: none;
        padding: 12px;
        font-size: 1rem;
        background-color: $color-btn-bg-gray;
    }
}
.AnnouncementList {
    padding-top: 175px;
    padding-left: 93px;
    margin-bottom: -90px;
    @media screen and (max-width: $Tab) {
        padding-top: 50px;
        padding-left: 0;
    }
    .NewsItem {
        margin-bottom: 90px;
    }
}
.Pagination {
    padding: 120px 0;
    .content {
        display: flex;
        justify-content: center;
        .el-pager {
            li {
                font-size: 0.875rem;
                &.is-active {
                    background-color: $color-primary-black;
                    color: white;
                    border-radius: 5px;
                }
            }
        }
    }
    
}
</style>
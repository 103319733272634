<script setup>
import { defineProps } from 'vue'

const props = defineProps({
    ItemData: {
        type: Object,
        required: true,
        default() {
            return {
                category_alias: "normal",
                tag: "新聞發佈",
                tagEn: "Press",
                imgUrl: "",
                date: "2024/11/20",
                title: "國家級運科中心推動運動科學研究，讓台灣持續在國際體育賽事上大放異彩",
                titleEn: "國家級運科中心推動運動科學研究，讓台灣持續在國際體育賽事上大放異彩",
                content: "選手將能夠透過國家級的專業後勤團隊，獲得更多體能訓練、運動防護、物理治療、運動心理及運動營養等支援，讓他們的訓練可以更安全、更抓得準方向",
            }
        }
    }
})

</script>
<template>
    <div class="NewsItemBox" :class="{ 'video': props.ItemData.extrafields? props.ItemData.extrafields.link:false }">
        <div v-if="props.ItemData.video" class="NewsItemImg">
            <img v-if="props.ItemData.introimage" :src="props.ItemData.introimage" alt="">
            <img v-else-if="props.ItemData.alias === '1'" src="@/assets/images/video/card_ achievements_img.jpg" alt="">
            <img v-else-if="props.ItemData.alias === '2'" src="@/assets/images/video/card_media_img.jpg" alt="">
            <img v-else src="@/assets/images/news/recruit_img.jpg" alt="">
            <div class="tag">{{ $i18n.locale === 'zh-TW' ? props.ItemData.category_title : props.ItemData.category_alias }}</div>
            <div class="Mask">{{ $t("READMORE") }}</div>
        </div>
        <div v-else class="NewsItemImg">
            <img v-if="props.ItemData.introimage" :src="props.ItemData.introimage" alt="">
            <img v-else-if="props.ItemData.alias === '1'" src="@/assets/images/news/about_img_1.jpg" alt="">
            <img v-else-if="props.ItemData.alias === '2'" src="@/assets/images/news/institute_img_1.jpg" alt="">
            <img v-else-if="props.ItemData.alias === '3'" src="@/assets/images/news/recruit_img_1.jpg" alt="">
            <img v-else-if="props.ItemData.alias === '4'" src="@/assets/images/news/article_img.jpg" alt="">
            <img v-else src="@/assets/images/news/recruit_img.jpg" alt="">
            <div class="tag">{{ $i18n.locale === 'zh-TW' ? props.ItemData.category_title : props.ItemData.category_alias }}</div>
            <div class="Mask">{{ $t("READMORE") }}</div>
        </div>
        <el-text line-clamp="2" class="NewsItemTitle">{{ props.ItemData.title }}</el-text>
        <div class="NewsItemContent">
            <el-text line-clamp="2" v-html="props.ItemData.introtext"></el-text>
        </div>
        <div class="NewsItemInfo">
            <el-tag v-if="props.ItemData.tags" effect="plain" round>{{ props.ItemData.tags[0]? props.ItemData.tags[0].title:"" }}</el-tag>
            <span>{{ $formater.dateFormate(props.ItemData.publish_up, "Y/M/D") }}</span>
        </div>
    </div>

</template>
<style lang="scss" scope>
.NewsItemBox {
    .NewsItemImg {
        position: relative;
        width: 100%;
        height: 204px;
        border-radius: 10px;
        overflow: hidden;
        .tag {
            background-color: $color-primary-black;
            position: absolute;
            right: 0;
            bottom: 0;
            padding: 10px 22px;
            font-size: 1rem;
            color: white;
            z-index: 3;
            border-radius: 10px 0 10px 0;
            &::before {
                content: '';
                display: block;
                position: absolute;
                height: 1px;
                width: 1px;
                border-right: 12px solid $color-primary-black;
                border-top: 40px solid transparent;
                right: 100%;
            }
        }
        .Mask {
            display: none;
        }
        img {
            width: 100%;
            height: 100%;
        }
    }
    &.headline {
        .NewsItemImg {
            height: 396px;
        }
        .NewsItemTitle {
            font-size: 2rem;
        }
        .NewsItemContent {
            display: block;
            color: $color-gray;
            font-size: 1rem;
            margin-bottom: 14px;
            .el-text {
                width: 100%;
            }
        }
    }
    .NewsItemTitle {
        font-size: 1.25rem;
        font-weight: 600;
        color: $color-black;
        margin: 20px 0 7px 0;
    }
    .NewsItemContent {
        display: none;
    }
    .NewsItemInfo {
        font-size: 0.875rem;
        color: $color-gray;
        .el-tag {
            font-size: inherit;
            margin-right: 20px;
            color: $color-primary;
            border-color: $color-primary;
        }
    }
    &:hover {
        .NewsItemImg {
            .tag {
                background-color: $color-primary;
                color: white;
                &::before {
                    border-right: 12px solid $color-primary;
                }
            }
            .Mask {
                display: flex;
                align-items: center;
                justify-content: center;
                position: absolute;
                width: 100%;
                height: 100%;
                top: 0;
                left: 0;
                background: linear-gradient(to left, rgba(119, 203, 242, 0.7), rgba(0, 77, 159, 0.7));
                color: white;
            }
        }
        .NewsItemTitle {
            color: $color-primary;
        }
    }
}
.Home {
    .NewsItemBox {
        &.video {
            .tag {
                background-color: white;
                color: $color-primary;
                &::before {
                    border-right: 12px solid white;
                }
            }
            .NewsItemTitle {
                color: white;
            }
            .NewsItemInfo {
                color: white;
                .el-tag {
                    background-color: transparent;
                    color: white;
                    border-color: white;
                }
            }
            &:hover {
                .NewsItemTitle {
                    color: $color-lighter-primary;
                }
                .NewsItemImg {
                    .tag {
                        background-color: $color-primary;
                        color: white;
                        &::before {
                            border-right: 12px solid $color-primary;
                        }
                    }
                    .Mask {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        position: absolute;
                        width: 100%;
                        height: 100%;
                        top: 0;
                        left: 0;
                        background: linear-gradient(to left, rgba(119, 203, 242, 0.7), rgba(0, 77, 159, 0.7));
                        color: white;
                    }
                }
            }
        }
    }
}
</style>
<script setup>
import { ref, defineProps, defineEmits, watch } from 'vue'

const emits = defineEmits(['current-change']);
const props = defineProps({
    position: {
        type: String,
        default() {
            return "left"
        }
    },
    layout: {
        type: Array,
        default() {
            return ['prev', 'pager', 'next']
        }
    },
    pagination: {
        type: Object,
        default() {
            return {
                current_page: 1,
                first_page_url: "",
                from: 1,
                last_page: 2,
                last_page_url: "",
                next_page_url: "",
                path: "",
                per_page: 9,
                prev_page_url: null,
                to: 1,
                total: 2
            }
        }
    },
    defaultPageSize: {
        type: Number,
        default() {
            return 9
        }
    },
    prevIcon: {
        type: Array,
        default() {
            return ['fas', 'chevron-left']
        }
    }
})

const defaultLayoutOption = ["pager", "jumper", "total"]
const showPrev = ref(false)
const showNext = ref(false)
const showGoFirst = ref(false)
const showGoLast = ref(false)
const currentPage = ref(1)
const getLayout = () => {
    let result = ""
    props.layout.forEach((item) => {
        if(defaultLayoutOption.includes(item)) {
            result += item+", "
        }
        if(item === "prev") {
            showPrev.value = true
        }
        if(item === "next") {
            showNext.value = true
        }
        if(item === "go-first") {
            showGoFirst.value = true
        }
        if(item === "go-last") {
            showGoLast.value = true
        }
    })

    return result
}
const currentChangeHandler = () => {
    emits("current-change", currentPage.value)
}
const nextPage = (toEnd = false) => {
    let guard = true
    if(toEnd) {
        currentPage.value = props.pagination.last_page
    } else {
        if(currentPage.value < props.pagination.last_page) {
            currentPage.value += 1
        } else {
            guard = false
        }
    }
    if(guard) {
        currentChangeHandler()
    }
}
const prevPage = (toStart = false) => {
    let guard = true
    if(toStart) {
        currentPage.value = 1
    } else {
        if(currentPage.value > 1) {
            currentPage.value -= 1
        } else {
            guard = false
        }
    }
    if(guard) {
        currentChangeHandler()
    }
}
watch(props, () => {
    currentPage.value = localStorage.getItem("page")? Number(localStorage.getItem("page")):props.pagination.current_page
})
</script>
<template>
    <div class="BasePagination" :class="props.position">
        <el-button v-if="showGoFirst" aria-label="head-page" class="BtnTool" :disabled="currentPage <= 1" @click="prevPage(true)"><font-awesome-icon :icon="['fas', 'angles-left']" /></el-button>
        <el-button v-if="showPrev" aria-label="prev-page" class="BtnTool" :disabled="currentPage <= 1" @click="prevPage()"><font-awesome-icon :icon="['fas', 'chevron-left']" /></el-button>
        <el-pagination small :layout="getLayout()" 
            v-model:current-page="currentPage"
            :page-size="props.pagination.per_page"
            :default-page-size="props.defaultPageSize"
            :total="props.pagination.total"
            @current-change="currentChangeHandler()"
        />
        <el-button v-if="showNext" aria-label="next-page" class="BtnTool" :disabled="currentPage >= props.pagination.last_page" @click="nextPage()"><font-awesome-icon :icon="['fas', 'chevron-right']" /></el-button>
        <el-button v-if="showGoLast" aria-label="tail-page" class="BtnTool" :disabled="currentPage >= props.pagination.last_page" @click="nextPage(true)"><font-awesome-icon :icon="['fas', 'angles-right']" /></el-button>
    </div>
</template>
<style lang="scss" scope>
.BasePagination {
    display: flex;
    &.center {
        justify-content: center;
    }
    &.left {
        justify-content: left;
    }
    &.right {
        justify-content: right;
    }
    .el-pager {
        .is-active {
            background-color: $color-primary-black;
            color: white;
            border-radius: 5px;
        }
    }
    .BtnTool {
        border: 0;
        margin: 0;
    }
}
</style>
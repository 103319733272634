import { createI18n } from "vue-i18n";
import ch from "./zh-Hant.json";
import en from "./en.json";

const i18n = new createI18n({
  legacy: false,
  locale: "zh-TW",
  messages: {
    'zh-Hant': ch,
    'zh-TW': ch,
    en
  },
  fallbackLocale: "zh-TW",
});

export { i18n };
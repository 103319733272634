export const facebook = {
    init: async () => {
        return new Promise((resolve, reject) => {
            (function(d, s, id)
                {
                    var js, fjs = d.getElementsByTagName(s)[0];
                    if (d.getElementById(id)) {return;}
                    js = d.createElement(s); js.id = id;
                    js.src = "https://connect.facebook.net/zh-TW/sdk.js";
                    js.onerror = (error) => reject(error);
                    fjs.parentNode.insertBefore(js, fjs);
                }(document, 'script', 'facebook-jssdk')
            );
            window.fbAsyncInit = function() {
                window.FB.init({
                    appId            : '802247691807176', //正式：802247691807176 測試：1540297129874247
                    xfbml            : true,
                    version          : 'v19.0'
                });
            };
            window.fbAsyncInit.onload = () => resolve();
            window.fbAsyncInit.onerror = (error) => reject(error);
        })
    },
    fixMsgBoardCss: () => {
        let styleElementsCount = 0
        let styleElementsCountCheck = -1
        let findCount = 0
        const getFbStyleElement = setInterval(() => {
            const styleElements = document.querySelectorAll('head style');
            styleElementsCount = styleElements.length
            for (const styleElement of styleElements) {
                if(!!styleElement) {
                    if(styleElement.innerHTML.indexOf("font-size:") > -1) {
                        const regex = /font-size:\s*(\d+)px/g; // 正则表达式匹配所需格式
                        const matches = styleElement.innerHTML.matchAll(regex); // 查找文本中的所有匹配项
                        if(!!styleElement.getAttribute("data-fbcssmodules")) { // 無障礙報錯僅發生於FB留言版套件，因此只修改這部分
                            for (const match of matches) {
                                const fontSize = match[1]; // 提取数字字体大小
                                // console.log("找到的字体大小:", fontSize);
                                styleElement.innerHTML = styleElement.innerHTML.replace("font-size:"+fontSize+"px", "font-size:" + fontSize / 16 + "rem")
                            }
                        }
                    }
                }
            }
            findCount++
            if(styleElementsCountCheck === styleElementsCount && styleElementsCount !== 0) {
                if(styleElementsCount !== 0 || findCount >= 100) {
                    clearInterval(getFbStyleElement);
                }
            } else {
                styleElementsCountCheck = styleElementsCount
            }
        }, 200);
    }
}
<script setup>
import { ref, defineProps, onMounted } from 'vue'
import SectionBannerItem from "./SectionBannerItem.vue"

const props = defineProps({
	BannerList: {
		type: Array,
		default() {
			return [
				{
					backgroundImgUrl: "",
					mainTitle: "國家運動科學中心",
					mainTitleEn: "TAIWAN INSTITUTE OF <br> SPORTS SCIENCE",
					subTitle: "Taiwan Institute of Sports Science",
					content: "致力於推動全國運動科學研究<br>培訓優秀說及培育運動科學人才",
					contentEn: "Committed to promoting national sports science research <br> Training outstanding athletes and nurturing sports science talents",
					moreBtn: {
						show: true,
						text: "了解更多",
						textEn: "Learn more",
						color: "white",
						icon: ['fas', 'chevron-right']
					}
				}
			]
		} 
	}
})

const showCarouselSpot = ref("none")
onMounted(() => {
	setTimeout(() => {
		showCarouselSpot.value = ""
	}, 5000)
})
</script>
<template>
    <div class="SectionBanner">
        <el-carousel trigger="click" :autoplay="false" arrow="always" :indicator-position="showCarouselSpot" height="520px">
            <el-carousel-item v-for="item in props.BannerList" :key="item">
                <SectionBannerItem :ItemData="item" />
            </el-carousel-item>
        </el-carousel>
		<div class="ScrollHintBar"></div>
    </div>
</template>
<style lang="scss">
//注意這邊的scss沒有使用scope，引用此元件可能會污染該頁面的樣式
@keyframes ScrollHint {
	0% {
		top: -10px
	}
	40%{
		top: 100%;
	}
	100% {
		top: 100%;
	}
}
.SectionBanner {
	position: absolute;
	top: 0;
	left: 0;
	width: 100vw;
	height: 520px;
	&::after {
		content: '';
		display: block;
		position: absolute;
		width: 100%;
	}
	.el-carousel {
		.el-carousel__arrow {
			background-color: white;
			color: $color-primary;
			width: 56px;
			height: 56px;
			font-size: 1rem;
			@media screen and (max-width: $Tab) {
				top: 85%;
			}
			&:hover {
				background-color: $color-primary;
				color: white;
			}
			&--right {
				right: 30px;
				@media screen and (max-width: $Tab) {
					right: calc( 50% - 48px - 14px);
				}
			}
			&--left {
				@media screen and (max-width: $Tab) {
					left: calc( 50% - 48px - 14px);
				}
			}
		}
		.el-carousel__indicators.el-carousel__indicators--horizontal {
			bottom: 25%;
			.el-carousel__button {
				width: 10px;
				height: 10px !important;
				border-radius: 50%;
			}
		}
	}
	.ScrollHintBar {
		position: absolute;
		width: 1px;
		height: 110px;
		background-color: rgba(255, 255, 255, 0.2);
		left: 50%;
		bottom: 0;
		overflow: hidden;
        @media screen and (max-width: $Tab) {
			display: none;
		}
		&::before {
			box-shadow: 0 0 25px 5px white;
			position: absolute;
			content: '';
			display: block;
			width: 1px;
			height: 25px;
			background-color: white;
			z-index: 2;
			animation: ScrollHint 2s infinite ease-in-out;
		}
	}
}
</style>
<script setup>
import { watch, defineProps, onBeforeMount, onMounted } from 'vue'
import { useI18n } from 'vue-i18n';
import { useAboutStore } from '@/stores/about';

import BasePageLayout from '@/components/Base/BasePageLayout/Index.vue';
// import BaseBtn from "@/components/Base/BaseBtn.vue"

const { locale } = useI18n();
const aboutStore = useAboutStore()
const aboutData = aboutStore.data

const props = defineProps({
    hash: {
        type: String
    }
})

watch(locale, (nv, ov) => {
    if(ov !== localStorage.getItem("lang")) {
        aboutStore.initData()
    }
})

// const isShowTable = reactive({
//     "BOD": false,
//     "Units" : false
// })

// const OpenOmit = (name) => {
//     isShowTable[name] = !isShowTable[name]
// }
onBeforeMount(() => {
    aboutData.categoryList.menuData.active = props.hash? props.hash.replace("#", ""):""
    aboutStore.initData()
});
onMounted(() => {
    if(aboutData.categoryList.menuData.active) {
        const targetElement = document.getElementById(aboutData.categoryList.menuData.active);
        if(targetElement) {
            const { top } = targetElement.getBoundingClientRect();
            setTimeout(() => {
                window.scrollTo({
                    top: window.scrollY + top,
                    behavior: 'smooth',
                });
            }, 300)
        }
    }   
});
</script>
<template>
    <BasePageLayout :BannerData="aboutData.bannerData" :BreadcrumbData="aboutData.breadcrumbData" :AsideData="aboutData.categoryList" bgColor="defaultO4">
        <div v-if="props.hash === '#BOD'" class="BOD">
            <span id="BOD" class="anchor"></span>
            <div class="content">
                <div class="contentBox">
                    <div class="SubTitle">
                        <span v-if="$i18n.locale === 'zh-TW'">本屆董事會</span><span class="primary">Board of Directors</span>
                    </div>
                    <el-divider></el-divider>
                    <div>
                        <el-table :data="aboutData.BODData" :show-header="false" height="auto" cell-class-name="TablePadding">
                            <el-table-column prop="jobTitle" label="職稱">
                                <template  #default="scope">
                                    <span class="primary d-block w-100">{{ scope.row.extrafields.position? scope.row.extrafields.position.value:scope.row.extrafields["en-position"]? scope.row.extrafields["en-position"].value:$t("ABOUT_TABLE_CHAIRMAN") }}</span>
                                </template>
                            </el-table-column>
                            <el-table-column prop="title" label="名字">
                                <template #default="scope">
                                    <span>{{ scope.row.title }}</span>
                                </template>
                            </el-table-column>
                            <el-table-column prop="description" label="介紹" min-width="157">
                                <template #default="scope">
                                    <span v-html="scope.row.description"></span>
                                </template>
                            </el-table-column>
                        </el-table>
                        <!-- <div class="OpenOmit">
                            <BaseBtn :color="'white'" :text="isShowTable.BOD? $t('BTN_CLOSEOMIT'):$t('BTN_OPENOMIT')" :icon="isShowTable.BOD? ['fas', 'minus'] : ['fas', 'plus']" @click="OpenOmit('BOD')" />
                        </div> -->
                    </div>
                </div>
                <div class="contentBox">
                    <div class="SubTitle">
                        <span v-if="$i18n.locale === 'zh-TW'">監事</span><span class="primary">Supervisors</span>
                    </div>
                    <el-divider></el-divider>
                    <el-table :data="aboutData.supervisorData" :show-header="false" height="auto" cell-class-name="TablePadding">
                        <el-table-column prop="jobTitle" label="職稱">
                            <template  #default="scope">
                                <span class="primary d-block w-100">{{ scope.row.extrafields.position? scope.row.extrafields.position.value:scope.row.extrafields["en-position"]? scope.row.extrafields["en-position"].value:$t("ABOUT_TABLE_SUPERVISOR") }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="title" label="名字">
                            <template #default="scope">
                                <span>{{ scope.row.title }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="description" label="介紹" min-width="157">
                            <template #default="scope">
                                <span v-html="scope.row.description"></span>
                            </template>
                        </el-table-column>
                    </el-table>
                </div>
            </div>
        </div>
        <div v-else-if="props.hash === '#CEO'" class="CEO">
            <span id="CEO" class="anchor"></span>
            <div class="content">
                <div class="Profile">
                    <div class="SubTitle">    
                        <span v-if="$i18n.locale === 'zh-TW'">執行長</span><span class="primary">CEO</span><span class="Name">{{ aboutData.CEOData.title }}</span>
                    </div>
                    <el-divider></el-divider>
                    <div class="EduExp" v-html="aboutData.CEOData.description"></div>
                    <!-- <el-row :gutter="60">
                        <el-col :xs="24" :lg="12">
                            <div class="title">{{ $t("ABOUT_CEO_EDU") }}</div>
                            <ul class="list">
                                <li v-for="data in aboutData.CEOData.education" :key="data">
                                    {{ $i18n.locale === "zh-TW"? data.text:data.textEn }}
                                </li>
                            </ul>
                        </el-col>
                        <el-col :xs="24" :lg="12">
                            <div class="title">{{ $t("ABOUT_CEO_EXP") }}</div>
                            <ul class="list">
                                <li v-for="data in aboutData.CEOData.experience" :key="data">
                                    {{ $i18n.locale === "zh-TW"? data.text:data.textEn }}
                                </li>
                            </ul>
                        </el-col>
                    </el-row> -->
                </div>
                
                <div class="Contact">
                    <el-row :gutter="60">
                        <el-col :xs="24" :lg="12">
                            <div class="SubTitle">    
                                <span v-if="$i18n.locale === 'zh-TW'">聯絡窗口</span><span class="primary">Contact</span>
                            </div>
                        </el-col>
                        <el-col :xs="24" :lg="12">
                            <ul v-if="aboutData.CEOData.extrafields" class="list">
                                <li v-if="aboutData.CEOData.extrafields.secretary">
                                    <span class="label">{{ $t("ABOUT_CEO_SECRETARY") }}</span><span class="primary">{{ aboutData.CEOData.extrafields.secretary.value }}</span>
                                </li>
                                <li v-if="aboutData.CEOData.extrafields['ceo-phone']">
                                    <span class="label">{{ $t("ABOUT_CEO_TEL") }}</span><span class="primary">{{ aboutData.CEOData.extrafields["ceo-phone"].value }}</span>
                                </li>
                                <li v-if="aboutData.CEOData.extrafields['ceo-mail']">
                                    <span class="label">{{ $t("ABOUT_CEO_MAIL") }}</span><a :href="'mailto:' + aboutData.CEOData.extrafields['ceo-mail'].value"><span class="primary">{{ aboutData.CEOData.extrafields["ceo-mail"].value }}</span></a>
                                </li>
                            </ul>
                        </el-col>  
                    </el-row>
                </div>
            </div>
        </div>
        <div v-else-if="props.hash === '#Chairman'" class="CEO">
            <span id="CEO" class="anchor"></span>
            <div class="content">
                <div class="Profile">
                    <div class="SubTitle">    
                        <span v-if="$i18n.locale === 'zh-TW'">董事長</span><span class="primary">Chairman</span><span class="Name">{{ aboutData.ChairmanData.name }}</span>
                    </div>
                    <el-divider></el-divider>
                    <el-row :gutter="60">
                        <el-col :xs="24" :lg="12">
                            <div class="title">{{ $t("ABOUT_CEO_EDU") }}</div>
                            <ul class="list">
                                <li v-for="data in aboutData.ChairmanData.education" :key="data">
                                    {{ $i18n.locale === "zh-TW"? data.text:data.textEn }}
                                </li>
                            </ul>
                        </el-col>
                        <el-col :xs="24" :lg="12">
                            <div class="title">{{ $t("ABOUT_CEO_EXP") }}</div>
                            <ul class="list">
                                <li v-for="data in aboutData.ChairmanData.experience" :key="data">
                                    {{ $i18n.locale === "zh-TW"? data.text:data.textEn }}
                                </li>
                            </ul>
                        </el-col>
                    </el-row>
                </div>
                
                <div class="Contact">
                    <el-row :gutter="60">
                        <el-col :xs="24" :lg="12">
                            <div class="SubTitle">    
                                <span v-if="$i18n.locale === 'zh-TW'">聯絡窗口</span><span class="primary">Contact</span>
                            </div>
                        </el-col>
                        <el-col :xs="24" :lg="12">
                            <ul class="list">
                                <li>
                                    <span class="label">{{ $t("ABOUT_CEO_SECRETARY") }}</span><span class="primary">{{ $i18n.locale === "zh-TW"? aboutData.ChairmanData.secretaryName:aboutData.ChairmanData.secretaryNameEn }}</span>
                                </li>
                                <li>
                                    <span class="label">{{ $t("ABOUT_CEO_TEL") }}</span><span class="primary">{{ aboutData.ChairmanData.tel }}</span>
                                </li>
                                <li>
                                    <span class="label">{{ $t("ABOUT_CEO_MAIL") }}</span><span class="primary">{{ aboutData.ChairmanData.mail }}</span>
                                </li>
                            </ul>
                        </el-col>  
                    </el-row>
                </div>
            </div>
        </div>
        <div v-else-if="props.hash === '#Units'" class="Units">
            <span id="Units" class="anchor"></span>
            <div class="Part">
                <span id="SSRD" class="anchor"></span>
                <div class="content">
                    <div class="contentBox">
                        <div class="SubTitle">
                            <div v-if="$i18n.locale === 'zh-TW'" class="primary">Sports Science <br> Research Division</div>
                            <div v-else class="primary">Department of <br>Sports Science Research</div>
                            <div v-if="$i18n.locale === 'zh-TW'">
                                <span class="TitleIcon"><img src="@/assets/images/unit/ic_science.svg" alt=""></span>運動科學研究處
                            </div>
                        </div>
                        <div v-if="$i18n.locale === 'zh-TW'" class="Intro">運動科學研究處下分為「運科研究組」、「運科發展組」、「卓越表現組」三組，工作內容包含運動訓練與檢測開發、科技應用、數據分析、技戰術情蒐、運科新知轉譯教學、人才培育等。服務對象橫跨黃金計畫頂尖選手、培訓選手及基層運動。</div>
                        <div v-else class="Intro" style="text-align: start;">The Department of Sports Science Research is divided into 3 divisions: the Sports Science Research Division, the Sports Science Development Division, and the Performance Optimization Division. Its tasks include sports training and testing development, technology application, data analysis, technical and tactical intelligence collection, sports science knowledge translation and teaching, and talent cultivation. The Department supports athletes across all levels, from grassroots to talented individuals participating in the Golden Plan.</div>
                    </div>
                    <img class="MainImg d-none d-lg-block" src="@/assets/images/unit/science_img.jpg" alt="">
                    <img class="MainImg d-block d-lg-none" src="@/assets/images/unit/science_img_m.jpg" alt="">
                </div>
            </div>
            <div class="Part">
                <span id="STDD" class="anchor"></span>
                <div class="content">
                    <div class="contentBox">
                        <div class="SubTitle">
                            <div v-if="$i18n.locale === 'zh-TW'" class="primary">Sports Tech & <br> Development Division</div>
                            <div v-else class="primary">Department of <br>Sport Technology and <br>Information Development</div>
                            <div v-if="$i18n.locale === 'zh-TW'">
                                <span class="TitleIcon"><img src="@/assets/images/unit/ic_dev.svg" alt=""></span>運動科技與資訊開發處
                            </div>
                        </div>
                        <div v-if="$i18n.locale === 'zh-TW'" class="Intro">
                            運動科技與資訊開發處分三組，各組掌理事項如下：<br>
                            創新研發組：國內外運科產品研發及設備升級擴增支援效能。<br>
                            產學合作組：產學合作、駐點實習合作及促進國際學術交流研究。<br>
                            資訊科技開發組：建立系統軟體及資料庫系統整合與應用。
                        </div>
                        <div v-else class="Intro" style="text-align: start;">
                            The Department of Sport Technology and Information Development is divided into 3 divisions:<br>
                            Innovation and Invention Division: Developing sports science products and upgrading equipment to support performance enhancement.<br>
                            Industry Cooperation Division: Promoting industry-academia cooperation, internship programs, and international academic exchange.<br>
                            Technology and Information Development Division: Integrating and applying software and database systems.
                        </div>
                    </div>
                    <img class="MainImg d-none d-lg-block" src="@/assets/images/unit/dev_img.jpg" alt="">
                    <img class="MainImg d-block d-lg-none" src="@/assets/images/unit/dev_img_m.jpg" alt="">
                </div>
            </div>
            <div class="Part">
                <span id="SMRD" class="anchor"></span>
                <div class="content">
                    <div class="contentBox">
                        <div class="SubTitle">
                            <div v-if="$i18n.locale === 'zh-TW'" class="primary">Sports Medicine <br> Research Division</div>
                            <div v-else class="primary">Department of <br>Sports Medicine Research</div>
                            <div v-if="$i18n.locale === 'zh-TW'">
                                <span class="TitleIcon"><img src="@/assets/images/unit/ic_medicine.svg" alt=""></span>運動醫學研究處
                            </div>
                        </div>
                        <div v-if="$i18n.locale === 'zh-TW'" class="Intro">運動醫學研究處致力於研究與建立運動醫學與傷害防護相關檢測，分析運動傷害危險因子，制定運動員臨床治療與傷後回場訓練方針，以及提供傷後增強運動表現指引。並規劃臺灣運動醫學醫師培訓制度，建立臺灣運動醫學醫師人才資料庫。</div>
                        <div v-else class="Intro" style="text-align: start;">The Department of Sports Medicine Research is dedicated to advancing the field through research on sports medicine and injury prevention. This includes developing tests, analyzing risk factors, formulating treatment guidelines, and providing guidance for post-injury recovery and performance enhancement. Additionally, the department develops training programs for sports medicine physicians in Taiwan and maintains a database of these specialists.</div>
                    </div>
                    <img class="MainImg d-none d-lg-block" src="@/assets/images/unit/medicine_img.jpg" alt="">
                    <img class="MainImg d-block d-lg-none" src="@/assets/images/unit/medicine_img_m.jpg" alt="">
                </div>
            </div>
            <div class="Part">
                <span id="Admin" class="anchor"></span>
                <div class="content">
                    <div class="contentBox">
                        <div class="SubTitle">
                            <div v-if="$i18n.locale === 'zh-TW'" class="primary">Administration</div>
                            <div v-else class="primary">Department of <br>Administration</div>
                            <div v-if="$i18n.locale === 'zh-TW'">
                                <span class="TitleIcon"><img src="@/assets/images/unit/ic_dev.svg" alt=""></span>行政管理處
                            </div>
                        </div>
                        <div v-if="$i18n.locale === 'zh-TW'" class="Intro">
                            行政管理處分為五組，職責如下<br>
                            一、綜合規劃組：<br>制定計劃、內控制度、評估改善、行銷企劃。<br>
                            二、總務行政組：<br>總務、採購、財產、文書、出納、檔案。<br>
                            三、人資組：<br>綜理人力資源業務。<br>
                            四、財會組：<br>財務會計、預算決算、經費管控。<br>
                            五、資訊組：<br>資訊系統、網頁、硬體、資訊安全。
                        </div>
                        <div v-else class="Intro" style="text-align: start;">
                            The Department of Administration is divided into 5 divisions:<br>
                            Comprehensive Planning Division: <br>Planning, Internal control, Assessment, and Marketing<br>
                            General Affairs Division: General affairs, Procurement, Property, Document Management, Treasury, and Archiving.<br>
                            Human Resource Division: Human resources-related affairs.<br>
                            Financial Accounting Division: Finance, Accounting, Budgeting, Financial statements, and Budgetary control.<br>
                            Information Technology Division: Information systems, Websites, Hardware, Information security.
                        </div>
                    </div>
                    <img class="MainImg d-none d-lg-block" src="@/assets/images/unit/dev_img.jpg" alt="">
                    <img class="MainImg d-block d-lg-none" src="@/assets/images/unit/dev_img_m.jpg" alt="">
                </div>
            </div>
        </div>
        <div v-else>
            <div class="Objectives">
                <span id="Objectives" class="anchor"></span>
                <div class="content">
                    <div class="contentBox">
                        <div class="SubTitle">
                            <span v-if="$i18n.locale === 'zh-TW'">使命、願景</span><span class="primary">Objectives</span>
                        </div>
                        <div>
                            <div v-if="$i18n.locale === 'zh-TW'" class="Intro">推動運動科學研究及應用，提升國際運動競爭力</div>
                            <div v-else class="Intro">To promote our research achievements in sports science and to enhance our international athletic competitiveness</div>
                            <div v-if="$i18n.locale === 'zh-TW'" class="SubIntro">TO PROMOTE OUR RESEARCH ACHIEVEMENTS IN SPORTS SCIENCE AND TO ENHANCE OUR INTERNATIONAL ATHLETIC COMPETITIVENESS</div>
                        </div>
                    </div>
                    <img class="ObjectivesImg" src="@/assets/images/about/Objectives_img.jpg" alt="">
                </div>
            </div>
            <div class="Mission">
                <span id="Mission" class="anchor"></span>
                <div class="content">
                    <div class="SubTitle">
                        <span v-if="$i18n.locale === 'zh-TW'">中心任務</span><span class="primary">Mission</span>
                    </div>
                    <el-divider></el-divider>
                    <ul class="MissionList">
                        <li class="MissionItem">
                            <div class="MissionItemNumber">01</div>
                            <div class="MissionItemContent">{{ $t('ABOUT_MISSION_01') }}</div>
                        </li>
                        <li class="MissionItem">
                            <div class="MissionItemNumber">02</div>
                            <div class="MissionItemContent">{{ $t('ABOUT_MISSION_02') }}</div>
                        </li>
                        <li class="MissionItem">
                            <div class="MissionItemNumber">03</div>
                            <div class="MissionItemContent">{{ $t('ABOUT_MISSION_03') }}</div>
                        </li>
                        <li class="MissionItem">
                            <div class="MissionItemNumber">04</div>
                            <div class="MissionItemContent">{{ $t('ABOUT_MISSION_04') }}</div>
                        </li>
                    </ul>
                    <ul class="MissionList">
                        <li class="MissionItem">
                            <div class="MissionItemNumber">05</div>
                            <div class="MissionItemContent">{{ $t('ABOUT_MISSION_05') }}</div>
                        </li>
                        <li class="MissionItem">
                            <div class="MissionItemNumber">06</div>
                            <div class="MissionItemContent">{{ $t('ABOUT_MISSION_06') }}</div>
                        </li>
                        <li class="MissionItem">
                            <div class="MissionItemNumber">07</div>
                            <div class="MissionItemContent">{{ $t('ABOUT_MISSION_07') }}</div>
                        </li>
                        <li class="MissionItem last">
                            <div class="MissionItemNumber text-center"><font-awesome-icon :icon="['far', 'flag']" /></div>
                            <div class="MissionItemContent text-center">{{ $t('ABOUT_MISSION_08') }}</div>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="Organization">
                <span id="Organization" class="anchor"></span>
                <div class="content">
                    <div class="SubTitle">
                        <span v-if="$i18n.locale === 'zh-TW'">組織概況</span><span class="primary">Organization Structure</span>
                    </div>
                    <div class="content">
                        <el-row>
                            <el-col :sm="8" :xs="12">
                                <a href="#BOD" class="OrganizationItem">
                                    <div class="ItemTitle">{{ $t("ABOUT_ORGAN_BOARD") }}</div>
                                    <div v-if="$i18n.locale === 'zh-TW'" class="ItemSubTitle">Board of Directors</div>
                                </a>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col :sm="8" :xs="12">
                                <a href="#BOD" class="OrganizationItem">
                                    <div class="ItemTitle">{{ $t("ABOUT_ORGAN_CHAIRMAN") }}</div>
                                    <div v-if="$i18n.locale === 'zh-TW'" class="ItemSubTitle">Chairman</div>
                                </a>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col :sm="8" :xs="12">
                                <a href="#CEO" class="OrganizationItem">
                                    <div class="ItemTitle">{{ $t("ABOUT_ORGAN_CEO") }}</div>
                                    <div v-if="$i18n.locale === 'zh-TW'" class="ItemSubTitle">CEO</div>
                                </a>
                            </el-col>
                        </el-row>
                        <el-row :gutter="24" class="HasTwo">
                            <el-col :sm="8" :xs="12">
                                <div class="OrganizationItem">
                                    <div class="ItemTitle">{{ $t("ABOUT_ORGAN_ASSOCIATECEO") }}</div>
                                    <div v-if="$i18n.locale === 'zh-TW'" class="ItemSubTitle">Associate CEO</div>
                                </div>
                            </el-col>
                            <el-col :sm="8" :xs="12">
                                <div class="OrganizationItem">
                                    <div class="ItemTitle">{{ $t("ABOUT_ORGAN_ASSOCIATECEO") }}</div>
                                    <div v-if="$i18n.locale === 'zh-TW'" class="ItemSubTitle">Associate CEO</div>
                                </div>
                            </el-col>
                        </el-row>
                        <el-row :gutter="24" class="HasTwo">
                            <el-col :sm="8" :xs="12">
                                <div class="OrganizationItem">
                                    <div class="ItemTitle">{{ $t("ABOUT_ORGAN_CHIEFSECREATY") }}</div>
                                    <div v-if="$i18n.locale === 'zh-TW'" class="ItemSubTitle">Chief Secretary</div>
                                </div>
                            </el-col>
                        </el-row>
                        <el-row :gutter="24" class="HasFour">
                            <el-col :sm="6" :xs="12">
                                <div class="OrganizationItem">
                                    <div class="ItemTitle">{{ $t("ABOUT_ORGAN_DEPSSR") }}</div>
                                    <div v-if="$i18n.locale === 'zh-TW'" class="ItemSubTitle">Department of Sport Science Research</div>
                                </div>
                            </el-col>
                            <el-col :sm="6" :xs="12">
                                <div class="OrganizationItem">
                                    <div class="ItemTitle">{{ $t("ABOUT_ORGAN_DEPST&I") }}</div>
                                    <div v-if="$i18n.locale === 'zh-TW'" class="ItemSubTitle">Department of Sport Technology and Information</div>
                                </div>
                            </el-col>
                            <el-col :sm="6" :xs="12">
                                <div class="OrganizationItem">
                                    <div class="ItemTitle">{{ $t("ABOUT_ORGAN_DEPSMR") }}</div>
                                    <div v-if="$i18n.locale === 'zh-TW'" class="ItemSubTitle">Department of Sport Medicine Research</div>
                                </div>
                            </el-col>
                            <el-col :sm="6" :xs="12">
                                <div class="OrganizationItem">
                                    <div class="ItemTitle">{{ $t("ABOUT_ORGAN_DEPADMIN") }}</div>
                                    <div v-if="$i18n.locale === 'zh-TW'" class="ItemSubTitle">Department of Administration</div>
                                </div>
                            </el-col>
                        </el-row>
                    </div>
                </div>
            </div>
        </div>
    </BasePageLayout>
</template>
<style lang="scss" scope>
.Objectives {
    position: relative;
    background-image: url('@/assets/images/about/bg_img.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    padding: 160px 0 60px 133px;
    // overflow: hidden;
    @media screen and (max-width: $Tab) {
        padding: 16px 0 30px 0;
    }
    .content {
        display: flex;
        justify-content: center;
        width: 100%;
        max-width: 870px;
        // height: 384px;
        @media screen and (max-width: $Tab) {
            max-width: none;
            width: 100%;
            height: auto;
            display: block;
        }
        .contentBox {
            padding-right: 110px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            @media screen and (max-width: $Tab) {
                padding: 16px 20px;
            }
            .SubTitle {
                margin-bottom: 20px;
                span {
                    color: $color-black;
                    margin-right: 14px;
                    &.primary {
                        color: $color-primary;
                        margin-right: 0;
                    }
                }
            }
            .Intro {
                margin: 40px 0;
                color: $color-black;
                font-size: 2rem;
                @media screen and (max-width: $Tab) {
                    margin: 30px 0 10px;
                    font-size: 1.25rem;
                }
            }
            .SubIntro {
                color: $color-primary;
            }
        }
    }
    .ObjectivesImg {
        width: 50%;
        max-width: 835px;
        border-radius: 10px;
        @media screen and (max-width: $Tab) {
            width: calc(100% - 40px);
            margin: 16px 20px 0;
        }
    }
}
.Mission {
    background-color: $color-bg;
    padding: 60px 0 60px 133px;
    position: relative;
    @media screen and (max-width: $Tab) {
        padding: 30px 20px 50px;
    }
    .content {
        max-width: 870px;
        .SubTitle {
            margin-bottom: 20px;
            span {
                color: $color-black;
                margin-right: 14px;
                &.primary {
                    color: $color-primary;
                    margin-right: 0;
                }
            }
        }
        .MissionList {
            display: flex;
            padding: 16px 6px;
            background-color: white;
            height: 250px;
            @media screen and (max-width: $Tab) {
                height: auto;
                flex-wrap: wrap;
                margin-bottom: 0 !important;
                padding: 0 6px 16px;
            }
            &:not(:last-child) {
                margin-bottom: 30px;
                @media screen and (max-width: $Tab) {
                    padding: 16px 6px 0;
                }
            }
            .MissionItem {
                padding: 5px 23px;
                width: 25%;
                @media screen and (max-width: $Tab) {
                    margin-bottom: 14px;
                    width: 50%;
                    padding: 30px 15px;
                    min-height: 178px;
                    &:nth-child(2n) {
                        border-right: 0 !important;
                    }
                }
                &:not(:last-child) {
                    border-right: 1px solid $color-dash-border;
                }
                .MissionItemNumber {
                    color: $color-primary;
                    margin-bottom: 20px;
                    font-size: 1.25rem;
                }
                .MissionItemContent {
                    font-size: 1rem;
                    letter-spacing: 2.4px;
                }
                &.last {
                    background-color: rgba(0, 77, 159, 0.03);
                    display: flex;
                    align-items: center;
                    align-content: center;
                    justify-content: center;
                    flex-wrap: wrap;
                    @media screen and (max-width: $Tab) {
                        background-color: white;
                    }
                    .MissionItemNumber {
                        width: 100%;
                    }
                }
            }
        }
    }
}
.Organization {
    position: relative;
    background-image: url('@/assets/images/about/bg_img.jpg');
    background-repeat: no-repeat;
    padding: 60px 0 60px 133px;
    @media screen and (max-width: $Tab) {
        padding: 50px 20px 50px;
    }
    .content {
        max-width: 870px;
        .SubTitle {
            margin-bottom: 20px;
            span {
                color: $color-black;
                margin-right: 14px;
                &.primary {
                    color: $color-primary;
                    margin-right: 0;
                }
            }
        }
        .el-row {
            position: relative;
            align-items: center;
            justify-content: center;
            margin-top: 30px;
            margin-bottom: 15px;
            &:not(:first-child) {
                .OrganizationItem::before {
                    content: '';
                    display: block;
                    position: absolute;
                    height: 15px;
                    width: 1px;
                    border-left: 4px dotted $color-dash-border;
                    top: -15px;
                    left: 50%;
                }
                &.HasTwo::before {
                    content: '';
                    display: block;
                    position: absolute;
                    transform: translateX(-50%);
                    height: 1px;
                    width: calc(33% + 5px);
                    border-top: 4px dotted $color-dash-border;
                    top: -15px;
                    left: 50%;
                    @media screen and (max-width: $Tab) {
                        width: 50%;
                    }
                }
                &.HasFour {
                    @media screen and (max-width: $Tab) {
                        margin-top: 15px;
                        border: 4px dotted $color-dash-border;
                        padding: 10px;
                        margin-left: 0 !important;
                        margin-right: 0 !important;
                    }
                    .OrganizationItem {
                        height: 140px;
                        @media screen and (max-width: $Tab) {
                            margin-bottom: 20px;
                            @media screen and (max-width: $Tab) {
                                height: 120px !important;
                            }
                            &::before {
                                display: none;
                            }
                        }
                    }
                    &::before {
                        content: '';
                        display: block;
                        position: absolute;
                        transform: translateX(-50%);
                        height: 1px;
                        width: 75%;
                        border-top: 4px dotted $color-dash-border;
                        top: -15px;
                        left: 50%;
                        @media screen and (max-width: $Tab) {
                            display: none;
                        }
                    }
                }
            }
            &:not(:last-child) {
                .OrganizationItem::after {
                    content: '';
                    display: block;
                    position: absolute;
                    height: 15px;
                    width: 1px;
                    border-left: 4px dotted $color-dash-border;
                    bottom: -15px;
                    left: 50%;
                }
            }
        }
        .OrganizationItem {
            position: relative;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            flex-wrap: wrap;
            box-shadow: 5px 5px 10px $color-light-gray;
            background-color: white;
            border: 1px solid transparent;
            border-radius: 10px;
            width: 100%;
            height: 100px;
            padding: 0 8px;
            text-align: center;
            @media screen and (max-width: $Tab) {
                height: 60px !important;
            }
            .ItemTitle {
                width: 100%;
                color: $color-primary;
                font-size: 1.25rem;
                @media screen and (max-width: $Tab) {
                    font-size: 0.875rem;
                }
            }
            .ItemSubTitle {
                width: 100%;
                color: $color-gray;
                font-size: 0.875rem;
                @media screen and (max-width: $Tab) {
                    font-size: 0.72rem;
                    line-height: 12px;
                }
            }
            &:hover {
                border-color: $color-primary;
            }
        }
    }
}
.BOD {
    position: relative;
    padding: 160px 0 60px 133px;
    @media screen and (max-width: $Tab) {
        padding: 50px 20px 50px;
    }
    .content {
        width: 100%;
        max-width: 870px;
        .contentBox {
            &:not(:last-child) {
                margin-bottom: 70px;
            }
            .SubTitle {
                margin-bottom: 20px;
                span {
                    color: $color-black;
                    margin-right: 14px;
                    &.primary {
                        color: $color-primary;
                        margin-right: 0;
                    }
                }
            }
            .el-table {
                font-size: 1rem;
                .primary {
                    color: $color-primary
                }
                .TablePadding{
                    padding: 20px 9px;
                    @media screen and (max-width: $Tab) {
                        padding: 12px 0;
                    }
                }
            }
        }
    }
}
.CEO {
    position: relative;
    padding: 160px 0 60px 133px;
    @media screen and (max-width: $Tab) {
        padding: 50px 20px 50px;
    }
    .content {
        background-color: white;
        box-shadow: 0 3px 30px $color-shadow;
        padding: 20px;
        width: 100%;
        max-width: 870px;
        border-radius: 10px;
    }
    .SubTitle {
        display: flex;
        align-items: center;
        margin: 6px 0 20px 0;
        @media screen and (max-width: $Tab) {
            margin-left: 0px;  
            margin-top: 20px;  
        }
        span {
            color: $color-black;
            margin-right: 14px;
            font-size: 1.25rem;
            @media screen and (max-width: $Tab) {
                font-size: 1rem;
            }
            &:first-child {
                letter-spacing: 3px;
            }
            &.primary {
                color: $color-primary;
                margin-right: 0;
            }
            &.Name {
                color: $color-primary;
                font-size: 2rem;
                margin-left: 40px;
                @media screen and (max-width: $Tab) {
                    font-size: 1.5rem;
                }
            }
        }
    }
    .Profile {
        padding: 30px;
        @media screen and (max-width: $MD) {
            padding: 30px 0;
        }
        .title {
            font-size: 20px;
            font-weight: 500;
            margin-top: 30px;
        }
        .EduExp {
            .title {
                margin-top: 0px;
            }
            p {
                font-size: 1rem;
                font-weight: normal;
            }
        }
        .list {
            list-style: none;
            padding: 0;
            li {
                margin: 12px 0;
                font-size: 1rem;
                color: $color-text-light-gray;
                letter-spacing: 2.4px;
            }
        }
    }
    .Contact {
        background-color: $color-bg;
        padding: 40px 30px;
        border-radius: 10px;
        @media screen and (max-width: $Tab) {
            padding: 20px 30px;
            .SubTitle {
                margin-bottom: 8px;
            }
        }
        .list {
            list-style: none;
            padding: 0;
            margin-left: -30px;
            @media screen and (max-width: $Tab) {
                margin-left: 0;
            }
            li {
                margin: 12px 0;
                font-size: 1rem;
                color: $color-text-light-gray;
                .label {
                    position: relative;
                    padding-right: 14px;
                    @media screen and (max-width: $MD) {
                        margin-left: 0;
                    }
                    &::after {
                        content: '';
                        display: block;
                        position: absolute;
                        width: 1px;
                        height: 16px;
                        background-color: $color-black;
                        right: 0;
                        top: 50%;
                        transform: translateY(-50%);
                    }
                }
                .primary {
                    padding-left: 14px;
                    color: $color-primary;
                    margin-right: 0;
                }
            }
        }
    }

}
.Units {
    padding: 160px 0 60px 133px;
    @media screen and (max-width: $Tab) {
        padding: 30px 20px 50px;
    }
    .Part {
        position: relative;
        @media screen and (max-width: $Tab) {
            &:first-child {
                .content {
                    border-top: 0;
                }
            }
        }
        .content {
            width: 100%;
            max-width: 870px;
            padding: 60px 0 120px 0 ;
            border-top: 1px solid $color-light-gray;
            .contentBox {
                display: flex;
                justify-content: space-between;
                padding-bottom: 60px;
                @media screen and (max-width: $Tab) {
                    flex-direction: column;
                }
                .SubTitle {
                    margin-bottom: 20px;
                    div {
                        color: $color-black;
                        margin-right: 14px;
                        font-size: 2rem;
                        @media screen and (max-width: $Tab) {
                            font-size: 1rem;
                            display: flex;
                            align-items: center;
                        }
                        &.primary {
                            color: $color-primary;
                            margin-right: 0;
                            margin-bottom: 20px;
                            font-size: 3rem;
                            @media screen and (max-width: $Tab) {
                                font-size: 1.5rem;
                            }
                        }
                        .TitleIcon {
                            width: 68px;
                            height: 68px;
                            border-radius: 50%;
                            display: inline-flex;
                            align-items: center;
                            justify-content: center;
                            margin-right: 22px;
                            box-shadow: 0px 3px 30px 0px #3e68951a;
                        }
                    }
                }
                .Intro {
                    max-width: 315px;
                    color: $color-text-light-gray;
                    font-size: 1rem;
                    letter-spacing: 1.6px;
                    line-height: 2;
                    text-align: justify;
                }
            }
            .MainImg {
                width: 100%;
                border-radius: 10px;
            }
        }
    }
}
.Omit {
    position: relative;
    @media screen and (max-width: $MD) {
        height: 200px;
        overflow: hidden;
        padding-bottom: 80px;
        transition: 0.3s;
    }
    &.show {
        height: auto;
    }
    .OpenOmit {
        display: none;
        background: linear-gradient(to top, rgba(255, 255, 255, 1) 55%, rgba(255, 255, 255, 0));
        padding: 50px 0 10px 0;
        width: 100%;
        position: absolute;
        bottom: 0;
        z-index: 10;
        text-align: center;
        @media screen and (max-width: $MD) {
            display: block;
        }
    }
}
.anchor {
    position: absolute;
    top: -135px;
    @media screen and (max-width: $MD) {
        top: -70px
    }
}
</style>
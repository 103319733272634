<script setup>
import { onBeforeMount } from 'vue'
import { useGlobalStore } from '@/stores/global';
import { useI18n } from "vue-i18n";
const { locale } = useI18n();

const globalStore = useGlobalStore()
const globalData = globalStore.data

const fontsize_list = [
    {
        value: 14,
        text: "小",
        text_en: 'A<sup>-</sup>',
    },
    {
        value: 16,
        text: "中",
        text_en: 'A<sup>&nbsp;</sup>',
    },
    {
        value: 18,
        text: "大",
        text_en: 'A<sup>+</sup>',
    },
]

onBeforeMount(() => {
    globalStore.initPublicInfoMenuData()
})

const setFontSize = (value) => {
    if(value) {
        globalData.setFontSize(value)
    }
}
const setLang = () => {
    if(locale.value === "zh-TW") {
        locale.value = "en"
        localStorage.setItem("lang", locale.value)
    } else {
        locale.value = "zh-TW"
        localStorage.setItem("lang", "zh-Hant")
    }
}
</script>
<template>
    <div class="menuNav" :class="{'ShowMenu': globalData.isShowMenu}">
        <div class="container-lg">
            <nav class="navbar navbar-expand-lg navbar-light p-0">
                <button aria-label="Toggler" data-bs-toggle="collapse" class="navbar-toggler" type="button" @click="globalStore.toggleMenu()" value="toggler">
                    <font-awesome-icon v-if="globalData.isShowMenu"  :icon="['fas', 'xmark']" />
                    <font-awesome-icon v-else :icon="['fas', 'bars']" />
                </button>
                <div class="collapse navbar-collapse" :class="{ 'show': globalData.isShowMenu }" id="navbarSupportedContent">
                    <!-- Mobile -->
                    <div class="d-block d-lg-none">
                        <ul class="ToolList">
                            <!-- <li>
                                <el-button text>網站導覽</el-button>
                                <el-button text>常見問答</el-button>
                            </li> -->
                            <!-- <li>
                                
                                <el-button class="SearchBtn active" text circle plain><font-awesome-icon :icon="['fas', 'magnifying-glass']" /></el-button>
                                <div class="SearchKeywordInput">
                                    <el-input :placeholder="$t('INPUT_SEARCH_KEYWORD_PLACEHOLDER')" class="Input"></el-input>
                                </div>
                            </li> -->
                            <li>
                                <span>
                                    <el-button v-for="(btn, index) in fontsize_list" :key="index" class="FontSizeBtn" :class="{ 'active': globalData.fontSize === btn.value }" text circle plain v-html="$i18n.locale === 'zh-TW'? btn.text:btn.text_en" @click="setFontSize(btn.value)"></el-button>
                                </span>
                                <el-button class="LangBtn" round plain @click="setLang()">
                                    <font-awesome-icon :icon="['fas', 'globe']" />
                                    <span>{{ $i18n.locale === "zh-TW"? "En":"中文" }}</span>
                                </el-button>
                            </li>
                        </ul>
                        <el-divider />
                        <el-row :gutter="35">
                            <el-col v-for="(item, index) in globalStore.menuNavList" :key="index" :span="12">
                                <RouterLink class="MenuTitle" :to="item.to" @click="globalStore.toggleMenu()" >{{ $t(item.textKey) }}</RouterLink>
                                <ul v-if="item.childrenList" class="MenuList">
                                    <li v-for="(_item, _index) in item.childrenList" :key="_index">
                                        <a :href="_item.to" @click="globalStore.toggleMenu()" >{{ _item.text? _item.text : $t(_item.textKey) }}</a>
                                    </li>
                                </ul>
                            </el-col>
                        </el-row>
                    </div>
                    <!--Desktop -->
                    <ul class="navbar-nav mb-2 mb-lg-0 d-none d-lg-flex">
                        <li v-for="(item, index) in globalStore.menuNavList" :key="index" class="nav-item" @mouseover="globalStore.setHoverNavList(item.name)" @mouseleave="globalStore.setHoverNavList()">
                            <RouterLink v-if="item.to" class="nav-link" :to="item.to">
                                {{ $t(item.textKey) }}
                                <span v-if="item.childrenList">
                                    <font-awesome-icon :icon="['fas', 'chevron-down']" class="HoverIcon" />
                                    <font-awesome-icon :icon="['fas', 'chevron-up']" class="HoverIcon" />
                                </span>
                            </RouterLink>
                            <a v-else :href="item.href" class="nav-link">{{ $t(item.textKey) }}</a>
                        </li>
                    </ul>
                </div>
            </nav>
        </div>
    </div>
    <div class="ChildrenList d-none d-lg-block" :class="{ 'show': globalData.hoverNav.show }">
        <div class="container-lg">
            <ul class="List">
                <li v-for="(child, index) in globalData.hoverNav.list" :key="index">
                    <a class="ItemLink" :href="child.to">
                        {{ child.text? child.text : $t(child.textKey) }}<span class="ItemLinkIcon"><font-awesome-icon :icon="['fas', 'chevron-right']" /></span>
                    </a>
                </li>
            </ul>
        </div>
    </div>

</template>
<style lang="scss" scoped>
.menuNav {
    position: relative;
    box-shadow: inset 0 0 10px rgba(0,168,134,0.2), 0 2px 20px $color-light-gray-trans5;
    background: linear-gradient(90deg, rgba(0,168,134,1) 0%, rgba(0,168,134,0.2) 45%, rgba(255,255,255,0) 100%);
    background-color: white;
    @media screen and (max-width: $Tab) {
        background: linear-gradient(-90deg, rgba(0,168,134,0.5) 0%, rgba(0,168,134,0.2) 10%, rgba(255,255,255,0) 100%);
        background-color: white;
    }
    &.ShowMenu {
        @media screen and (max-width: $Tab) {
            background: white;
        }
    }

    .navbar {
        .navbar-toggler {
            border: 0;
            color: $color-primary;
            min-height: 60px;
            box-shadow: none;
        }
        @media screen and (max-width: $Tab) {
            justify-content: flex-end;
        }
        .navbar-collapse {
            justify-content: flex-end;
            transition: 0.5s;
            @media screen and (max-width: $Tab) {
                max-height: calc(100vh - 60px);
                padding: 0 18px;
                overflow: auto;
            }
            .nav-item {
                min-height: 75px;
                margin: 0 2rem;
                font-size: inherit;
                @media screen and (max-width: $PC) {
                    min-height: 60px;
                    margin: 0 0.8rem;
                }
                .nav-link {
                    display: flex;
                    align-items: center;
                    height: 100%;
                    color: $color-black;
                    font-size: inherit;
                    .HoverIcon {
                        display: inline;
                        & + .HoverIcon {
                            display: none;
                        }
                    }
                    &:hover {
                        color: $color-primary;
                        .HoverIcon {
                            display: none;
                            & + .HoverIcon {
                                display: inline;
                            }
                        }
                    }
                    &.active {
                        position: relative;
                        // color: $color-primary;
                        // &::after {
                        //     content: "";
                        //     transform: translateX(-50%);
                        //     display: block;
                        //     position: absolute;
                        //     bottom: 0;
                        //     left: 50%;
                        //     width: 48px;
                        //     height: 3px;
                        //     background-color: $color-primary;
                        // }
                        // .el-dropdown-link {
                        //     color: $color-primary;
                        // }
                    }
                    .el-dropdown-link {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        height: 100%;
                        color: $color-black;
                        box-shadow: none;
                        outline: 0 !important;
                        text-decoration: none;
                    }
                }
            }
            @media screen and (max-width: $Tab) {
                &.show {
                    height: calc(100vh - 60px);
                    .ToolList {
                        margin-top: 50px;
                        padding: 0;
                        li {
                            display: flex;
                            justify-content: center;
                            margin-bottom: 22px;
                            .FontSizeBtn {
                                margin-right: 16px;
                                &.active {
                                    background-color: $color-light-primary;
                                }
                            }
                            &:last-child {   
                                justify-content: space-between;
                            }
                            .SearchBtn {
                                background-color: $color-light-primary;
                                margin-right: 10px;
                                &.active {
                                    color: white;
                                    background-color: $color-primary;
                                    border-color: $color-primary;
                                }
                            }
                            .SearchKeywordInput{
                                border-color: $color-primary;
                                border-radius: 10px;
                                width: 100%;
                                .Input {
                                    width: 100%;
                                    :deep(.el-input__wrapper){
                                        border: 1px solid $color-primary !important;
                                        border-radius: 10px;
                                        width: 100%;
                                    }
                                }
                            }
                        }
                    }
                    .MenuTitle {
                        display: block;
                        padding: 16px 0;
                        border-bottom: 1px solid $color-divider;
                        font-size: 1.125rem;
                        letter-spacing: 2.7px;
                        color: $color-primary;
                        text-decoration: none;
                    }
                    .MenuList {
                        padding: 16px 0 0 0;
                        margin-bottom: 40px;
                        li {
                            margin-bottom: 12px;
                            a {
                                font-size: 1rem;
                                letter-spacing: 2.4px;
                                color: $color-black;
                                text-decoration: none;
                            }
                        }
                    }
                }
            }
        }
    }
    & + .ChildrenList {
        background-color: white;
        position: absolute;
        width: 100vw;
        height: 99%;
        top: 0;
        left: 0;
        transition: 0.3s;
        overflow: hidden;
        z-index: -1;
        &:hover, &.show {
            top: 100%;
            height: auto;
        }
        .List {
            display: flex;
            margin: auto;
            padding: 6px 0 16px 0;
            max-width: 1140px;
            flex-wrap: wrap;
            align-items: center;
            li {
                color: $color-black;
                border-bottom: 1px solid $color-light-gray;
                margin: 24px 40px;
                color: $color-black;
                &:hover {
                    color: $color-primary;
                    border-color: $color-primary;
                    .ItemLink{
                        .ItemLinkIcon {
                            background-color: $color-primary;
                            color: white;
                        }
                    }
                }
                .ItemLink {
                    color: inherit;
                    display: flex;
                    min-width: 300px;
                    align-items: center;
                    justify-content: space-between;
                    padding-bottom: 20px;
                    text-decoration: none;
                    .ItemLinkIcon {
                        width: 30px;
                        height: 30px;
                        border-radius: 50%;
                        background-color: white;
                        text-align: center;
                    }
                }
            }
        }
    }
}
</style>
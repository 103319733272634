import { reactive } from 'vue'
import { defineStore } from 'pinia'
import { useApiItemStore } from '@/stores/modules/api/item';
import { useApiModuleStore } from '@/stores/modules/api/module';
import { useApiCategoryStore } from '@/stores/modules/api/category';

export const useAnnouncementStore = defineStore("announcement", {
    state() {
        return {
            data: {
                bannerData: reactive({
                    pageName: "announcement",
                    title: "Announcements",
                    subTitle: "中心公告"
                }),
                breadcrumbData: reactive([
                    { text: "首頁", textEn: "Home", to: "/" },
                    { text: "最新消息", textEn: "News" },
                    { text: "中心公告", textEn: "Announcements" }
                ]),
                categoryList: reactive({
                    titleKey: "NAV_ANNOUNCEMENT",
                    menuData:{
                        mainUrl: "/announcement",
                        active: "",
                        list: [
                            { title: "全部文章", alias: "" }
                        ]
                    }
                }),
                list: reactive([]),
                pagination: reactive({
                    current_page: 1,
                    first_page_url: "",
                    from: 1,
                    last_page: 2,
                    last_page_url: "",
                    next_page_url: "",
                    path: "",
                    per_page: 9,
                    prev_page_url: null,
                    to: 9,
                    total: 10
                }),
                pageData: reactive({
                    title: "",
                    introtext: "",
                    introimage: ""
                })
            }
        }
    },
    actions: {
        async initIndexData() {
            await useApiModuleStore().fetchGetByAlias('search-announcement')
            const GetModuleResult = JSON.parse(JSON.stringify(useApiModuleStore().data.response))
            await this.initCategory(GetModuleResult.data.items.items.category_alias)
            await this.initIndexList()
        },
        async initIndexList() {
            const allCategoryArr = this.data.categoryList.menuData.list.filter((item) => {
                return item.alias !== ""
            })
            const requestData = { 
                page: this.data.pagination.current_page,
                limit: 9,
                category_alias: this.data.categoryList.menuData.active? [ this.data.categoryList.menuData.active ]:allCategoryArr.map(item => item.alias),
                language: localStorage.getItem("lang")? localStorage.getItem("lang"):"zh-Hant"
            }
            await useApiItemStore().fetchSearch(requestData)
            const result = JSON.parse(JSON.stringify(useApiItemStore().data.response))
            this.data.list = result.data.items
            this.data.pagination = result.data.pagination
            document.body.scrollTop = 0; // For Safari
            document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
        },
        async initCategory(ApiResultArr) {
            const tempArr = ApiResultArr.filter((item) => {
                return item !== "announcement"
            })
            if(tempArr) {
                this.data.categoryList.menuData.list = [
                    { title: localStorage.getItem("lang") === "zh-Hant"? "全部文章":"All", alias: "" }
                ]
                for (const item of tempArr) {
                    await useApiCategoryStore().fetchGetByAlias(item)
                    if(useApiCategoryStore().data) {
                        const result = JSON.parse(JSON.stringify(useApiCategoryStore().data.response))
                        this.data.categoryList.menuData.list.push(result.data.items)
                    }
                }
            }
        },
        async initPageData(alias) {
            try {
                await useApiModuleStore().fetchGetByAlias('search-announcement')
                const GetModuleResult = JSON.parse(JSON.stringify(useApiModuleStore().data.response))
                await this.initCategory(GetModuleResult.data.items.items.category_alias)
                await useApiItemStore().fetchGetByAlias(alias)
                const result = JSON.parse(JSON.stringify(useApiItemStore().data.response))
                this.data.pageData = result.data.items
            } catch (e) {
                window.location = "/announcement"
            }
        }
    }
})